.buglist {
    border: 0px solid #dde4ed;
}
.bugcontainer {
    width: 100%;
    height: calc(100% - 107px);
}
.buglist-header-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
}
.buglist-header-title-container-new {
    padding-top: 6px;
}
.new-bug-modal {
    background-color: #fff;
    border-radius: 5px;
    padding: 32px;
    width: 400px;
}
.add-button {
    border-radius: 5px;
    background-color: #3574F3;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
    border: solid 1px #3574F3;
    color: #fff;
}
.add-button path {
    color: #fff;
}
.add-button:hover path {
    color: #fff;
}
.buglistHeader {
    height: 107px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 2px solid #EBECF1;
    text-align: center;
    line-height: 47px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: 16px;
    padding-right: 16px;
}
.buglistHeaderTitle {
    color: #33375A;
    font-size: 22px;
    font-weight: bold;
    padding: 16px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
}
.buglistHeaderFilter {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 0px;
}
.bug {
    cursor: pointer;
    padding: 16px;
    position: relative;
    padding-bottom: 0px;
    border-bottom: 1px solid #dde4ed99;
    border-right: 0px solid #dde4ed99;
}
.bug:hover {
    background-color: #fff !important;
}
.bug:last-child {
    border-bottom: none;
}
.bugTitle {
    font-weight: bold;
    line-height: 22px;
    color: #33375A;
}
.bugDescription {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    height: 22px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.BoardColumn .bugDescription {
    margin-top: 0px;
    margin-bottom: 0px;
}
.bugStatusBubbleInlineBig {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 100%;
    margin-left: 8px;
    margin-right: 16px;
}
.bugStatusBubbleInline {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    margin-right: 4px;
}
.bugStatusBubble {
    position: absolute;
    top: 4px;
    bottom: 4px;
    left: 4px;
    width: 4px;
    border-radius: 10px;
}
.bugStorypointContainer {
    padding-top: 0px;
}
.bugStorypointContainer span {
    font-size: 12px;
}
.bugStorypointIcon {
    padding-right: 4px;
}
.bugTitleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.bugItemDate time {
    font-size: 12px;
    line-height: 22px;
}
.bugStateContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
}
.BoardColumn .bugStateContainer {
    border-bottom: 0px solid #dde4ed99;
    padding-bottom: 0px;
}
.no-reports-yet-tutorial {
    text-align: center;
    padding: 16px;
}
.no-reports-yet-tutorial-header {
    font-weight: bold;
}
.no-reports-yet-tutorial-body {
    margin-top: 8px;
    margin-bottom: 12px;
}
.dropzone {
    height: 100px;
    border: 2px dotted #eee;
    border-radius: 5px;
    margin-bottom: 16px;
    padding: 16px;
    width: 100%;
}
.dropzone p {
    text-align: center;
    font-weight: bold;
}