.project-members-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
    justify-content: center;
    flex-direction: row;
}

.project-members-list-user-item {
    position: relative;
}

.project-members-list-user-item-remove {
    position: absolute;
    top: -7px;
    right: 8px;
    background-color: #e1e6eb;
    width: 18px;
    height: 18px;
    border-radius: 9px;
    box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.08);
    cursor: pointer;
    line-height: 14px;
    padding: 2px;
    text-align: center;
}

.project-members-list-user-item-remove path {
    color: #617084;
}

.invited-email-body {
    border: 1px solid #dde4ed;
    box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.04);
    padding: 10px;
    margin: 10px;
    margin-right: 16px;
    margin-left: 0px;
    border-radius: 8px;
    background-color: #fff;
    position: relative;
    cursor: pointer;
    text-align: left;
    font-weight: bold;
    padding-right: 16px;
    display: flex;
    flex-direction: row;
}

.invited-email-body-image {
    height: 30px;
    border-radius: 15px;
    margin-right: 8px;
}

.invited-email-body-cont {
    display: flex;
    flex-direction: column;
}

.invited-email-body-main {
    font-weight: bold;
    display: block;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 17px;
}

.invited-email-body-sub {
    font-weight: 500;
    font-size: 11px;
    line-height: 12px;
    display: block;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
}

.members-nooneinvited {
    text-align: center;
    border: 1px solid #dde4ed;
    box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.04);
    padding: 10px;
    margin: 10px;
    margin-right: 16px;
    margin-left: 0px;
    border-radius: 8px;
    background-color: #fff;
    position: relative;
    cursor: pointer;
    text-align: left;
    font-weight: bold;
    text-align: center;
}

.project-board-state-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
    justify-content: center;
    flex-direction: row;
}

.project-board-state-list-user-item {
    position: relative;
    width: 100%;
}

.project-board-state-list-user-item .state-item-bubble {
    padding: 10px;
    padding-right: 16px;
    padding-left: 38px;
    margin-right: 0px;
}

.project-board-state-list-user-item-remove {
    position: absolute;
    top: -7px;
    right: -8px;
    background-color: #e1e6eb;
    width: 18px;
    height: 18px;
    border-radius: 9px;
    box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.08);
    cursor: pointer;
    line-height: 14px;
    padding: 2px;
    text-align: center;
}

.project-board-state-list-user-item-remove path {
    color: #617084;
}