.integration-token-viewer-code {
  display: block;
  padding: 20px;
  background: #ececec;
  color: #000;
  border-radius: 8px;
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: center;
  font-size: 18px;
}

.IntegrationCardNew {
  padding: 32px;
  border-radius: 5px;
  border: 2px solid #dde4ed;
  background-color: #fff;
  margin-left: -15px;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: visible;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
}

.IntegrationCard {
  padding: 32px;
  border: 0px solid #dde4ed;
  border-radius: 5px;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  margin-left: -15px;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: visible;
}

.IntegrationCardLogo {
  width: 30px;
  height: 30px;
  padding: 6px;
  object-fit: contain;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
  border-radius: 45px;
  top: -10px;
  right: -10px;
}

.IntegrationCardContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 8px;
}

.IntegrationCardContentTitle {
  line-height: 20px;
  font-weight: bold;
  color: #33375a;
}

.sdk-container {
  max-width: 900px;
}
