.bugcard-container {
    border: 1px solid #dde4ed;
    background-color: #fff;
    width: calc(100% - 32px);
    border-radius: 5px;
    margin: 16px;
    margin-top: 8px;
    margin-bottom: 0px;
    padding: 10px;
    position: relative;
    cursor: move;
    cursor: grab;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
}

.bugcard-container-placeholder {
    width: calc(100% - 16px);
    padding: 10px;
    height: 88px;
}

.bugStorypointContainerFlex {
    display: flex;
    flex-direction: row;
}