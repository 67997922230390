.featureInProgress {
    width: 100%;
    padding: 10px;
    background-color: #3574F3;
    border-radius: 5px;
    margin-bottom: 20px;
    color: #fff;
    text-align: center;
}

.bug-detail-no-data-how-to {
    padding: 32px;
    text-align: center;
    font-size: 16px;
}

.bug-detail-no-data-how-to a {
    font-size: 16px;
}

.navitem-container {
    background-color: #F4F7FC;
    padding: 32px;
    padding-bottom: 0px;
    padding-top: 52px;
    border-bottom: 2px solid #EBECF1;
}

.bugdetails-modal-container .navitem-container {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.tab-content {
    padding: 32px;
    max-height: calc(100vh - 107px);
    overflow: auto;
}