.dashboard-loading-container {
    position: absolute;
    top: calc(50% - 2px);
    left: calc(50% - 50px);
}

.Card {
    padding: 16px;
    border: 0px solid #dde4ed;
    background-color: #fff;
    margin: 32px;
    margin-left: 0px;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
    color: #fff;
    position: relative;
}

.Card--icon {
    font-size: 60px;
    line-height: 60px;
    position: absolute;
    top: 50%;
    left: 0%;
    text-align: center;
    width: 100%;
    margin-top: -40px;
    color: #fff;
}

.Card--blue .Card--subtitle {
    color: #fff;
}

.Card--blue .Card--subtitle b {
    color: #fff;
}

.Card--subtitle {
    text-align: center;
    position: absolute;
    bottom: 24px;
    left: 0px;
    width: 100%;
}

.Card--WithBorderBottom {
    border-bottom: 4px solid #0941CD;
}

.Card--withicon {
    min-height: 220px;
}

.Card--withimage {
    min-height: 250px;
}

.Card--green {
    background-color: #50E3C2;
}

.Card--blue {
    background-image: linear-gradient(to right, #3574F3, #3574F3);
}

.Card--title {
    font-size: 28px;
    text-align: center;
}

.Card--body {
    text-align: center;
}

.Card--blue .Card--title {
    color: #fff;
}

.Card--blue .Card--body {
    color: #fff;
}

.Card--blue .Card--body b {
    color: #fff;
}