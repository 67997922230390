
.CardBoxBorder--Modal {
    width: 90vw;
    max-width: 650px;
}

.programingLanguageSelection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 16px;
}

.programingLanguageSelection button:first-of-type {
    margin-right: 6px;
}

.programingLanguageSelection .btn-primary {
    padding: 0.55rem 0.75rem;
}

.success-panel .div {
    text-align: center;
    font-size: 20px;
}

.success-panel .boldtext {
    text-align: center;
    font-size: 22px;
}

.IntegrationModalBody {
    padding: 16px 0px;
}

.IntegrationModalBodyButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.success-panel .IntegrationModalBodyButtons {
    justify-content: center;
}