.stateColorPickerPreview {
    width: 42px;
    height: 42px;
    position: relative;
    cursor: pointer;
}

.stateColorPickerPreviewPicker {
    position: absolute;
    top: 40px;
    left: 0px;
    z-index: 9999;
}

.stateColorPickerPreviewColor {
    width: 100%;
    height: 100%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.state-item-bubble {
    border: 2px solid #dde4ed;
    box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.04);
    padding: 8px;
    margin: 12px;
    margin-right: 16px;
    margin-left: 0px;
    margin-top: 0px;
    border-radius: 5px;
    background-color: #fff;
    position: relative;
    cursor: pointer;
    text-align: left;
    font-weight: bold;
    padding-right: 16px;
    padding-left: 16px;
}
.bugStatusBubbleState {
    position: absolute;
    top: 16px;
    left: 16px;
    width: 12px;
    height: 12px;
    border-radius: 10px;
    z-index: 999;
}