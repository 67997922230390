.kanban-column {
    border: 0px solid #dde4ed;
    background-color: #fff;
    width: 280px;
    max-height: calc(100vh - 64px);
    border-radius: 5px;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
    margin: 32px;
    margin-right: -16px;
}

.kanban-column-header {
    height: 44px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
    line-height: 44px;
}

.kanban-column-header-stats {
    font-weight: normal;
}

.kanban-column-header-stats-label {
    font-size: 14px;
    padding-left: 12px;
    padding-right: 2px;
}

.kanban-column-header-stats svg {
    font-size: 14px;
    margin-left: 4px;
    padding-bottom: 2px;
}

.kanban-column-body {
    height: calc(100vh - 107px);
    overflow-y: auto;
    overflow-x: hidden;
}
.kanban-column-body-drag {
    padding: 0px;
    padding-bottom: 0px;
    height: calc(100vh - 107px);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.kanban-container-scroll {
    width: 100%;
    height: 100vh;
    overflow-x: auto;
}

.kanban-container-scroll-container {
    display: flex;
    height: 100vh;
    background-color: #F5F7FA;
}

.bug-modal-close-btn-full {
    position: absolute;
    top: 32px;
    right: 32px;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: #33375A;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
}

.bug-modal-close-btn-full:hover {
    background-color: #000;
}

.bug-modal-close-btn-full svg path {
    color: #fff;
}

.bug-modal-close-btn-modally {
    position: absolute;
    top: 26px;
    right: 4px;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: #33375A;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
}

.bug-modal-close-btn-modally:hover {
    background-color: #000;
}

.bug-modal-close-btn-modally svg path {
    color: #fff;
}

.bug-modal-close-btn {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: #33375A;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
}

.bug-modal-close-btn:hover {
    background-color: #000;
}

.bug-modal-close-btn svg path {
    color: #fff;
}

.gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
}

.gu-hide {
    display: none !important;
}

.gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}

.gu-transit {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
}

.BoardColumn {
    width: 100%;
    height: calc(100vh - 107px);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}