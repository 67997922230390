.LeftNavigationBar {
  padding-left: 30px;
  padding-top: 50px;
}

.StepsContainer {
}

.StepsHeader {
  margin-bottom: 30px;
  font-weight: bold;
}

.ActiveStep {
  color: 'blue';
  cursor: pointer;
}

.ButtonContainer {
  margin-top: 30px;
}

.ThankYouHeader {
  margin-top: 20px;
}

.ThankYouDesc {
  margin-top: 10px;
}
