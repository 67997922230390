.mainsidebar--container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    background-color: #fff;
    border-right: 2px solid #EBECF1;
}

.mainsidebar--logo {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 14px;
    width: 65px;
}

.mainsidebar--logo:hover {
    background-color: #e1e6eb3d;
}

.mainsidebar--logo img {
    width: 100%;
    height: auto;
    height: auto;
    object-fit: contain;
}

.mainsidebar--items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    padding-bottom: 13px;
    padding-top: 0px;
    margin-top: 41px;
}

.mainsidebar--project-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  cursor: pointer;
  margin-top: 8px;
  text-transform: uppercase;
  background-color: #fff;
  border: 2px solid #dde4ed;
  color: #33375a;
  font-weight: bold;
  font-size: 14px;
  line-height: 42px;
  text-align: center;
}

.mainsidebar--project-item:hover {
  border: 2px solid #3574f3;
  color: #3574f3;
}

.mainsidebar--item {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px;
    cursor: pointer;
    margin-top: 0px;
    font-size: 22px;
    width: 63px;
    height: 55px;
}

.mainsidebar--item svg path {
    color: #33375A;
}

.mainsidebar--item:hover svg path {
  color: #135DEE;
}

.mainsidebar--item--active {
  background-color: #F5F7FA;
  border-left: 3px solid #3574F3;
}

.mainsidebar--item--active svg path {
  color: #3574F3;
}

.mainsidebar--spacer {
    flex-grow: 1;
}

.mainsidebar--userinfo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65px;
    cursor: pointer;
    margin-top: 8px;
    font-size: 20px;
    position: relative;
    padding-bottom: 24px;
    padding-top: 24px;
}

.mainsidebar--userinfo:hover {
  background-color: #e1e6eb3d;
}

.mainsidebar--userinfo-trophy {
    text-align: center;
}

.mainsidebar--userinfo-trophy-points {
    font-size: 14px;
    font-weight: bold;
    color: #33375A;
    display: block;
    text-align: center;
}

.mainsidebar--userinfo-trophy-icon {
    display: block;
    text-align: center;
    font-size: 18px;
}

.mainsidebar--userinfo-trophy span svg path {
    color: #33375A;
}

.mainsidebar--userinfo img {
    width: 36px;
    height: auto;
    object-fit: contain;
    border-radius: 50%;
}

/** TOOLTIPS **/
.rc-tooltip.rc-tooltip-zoom-enter,
.rc-tooltip.rc-tooltip-zoom-leave {
  display: block;
}
.rc-tooltip-zoom-enter,
.rc-tooltip-zoom-appear {
  opacity: 0;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  animation-play-state: paused;
}
.rc-tooltip-zoom-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
  animation-play-state: paused;
}
.rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active,
.rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
  animation-name: rcToolTipZoomIn;
  animation-play-state: running;
}
.rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
  animation-name: rcToolTipZoomOut;
  animation-play-state: running;
}
@keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
}
@keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
}
.rc-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  font-size: 12px;
  line-height: 1.5;
  opacity: 1;
}
.rc-tooltip-hidden {
  display: none;
}
.rc-tooltip-placement-top,
.rc-tooltip-placement-topLeft,
.rc-tooltip-placement-topRight {
  padding: 5px 0 9px 0;
}
.rc-tooltip-placement-right,
.rc-tooltip-placement-rightTop,
.rc-tooltip-placement-rightBottom {
  padding: 0 5px 0 9px;
}
.rc-tooltip-placement-bottom,
.rc-tooltip-placement-bottomLeft,
.rc-tooltip-placement-bottomRight {
  padding: 9px 0 5px 0;
}
.rc-tooltip-placement-left,
.rc-tooltip-placement-leftTop,
.rc-tooltip-placement-leftBottom {
  padding: 0 9px 0 5px;
}
.rc-tooltip-inner {
  padding: 8px 10px;
  color: #617084;
  text-align: left;
  text-decoration: none;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
  min-height: 34px;
}
.rc-tooltip-inner span {
    color: #33375A;
    font-weight: bold;
}
.rc-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  bottom: 4px;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #e1e6eb;
}
.rc-tooltip-placement-top .rc-tooltip-arrow {
  left: 50%;
}
.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
  left: 15%;
}
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  right: 15%;
}
.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  left: 4px;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #e1e6eb;
}
.rc-tooltip-placement-right .rc-tooltip-arrow {
  top: 50%;
}
.rc-tooltip-placement-rightTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  bottom: 15%;
}
.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  right: 4px;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #e1e6eb;
}
.rc-tooltip-placement-left .rc-tooltip-arrow {
  top: 50%;
}
.rc-tooltip-placement-leftTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  bottom: 15%;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  top: 4px;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #e1e6eb;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  left: 50%;
}
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
  left: 15%;
}
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  right: 15%;
}