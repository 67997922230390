.LoginContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.LoginContainer .LoginForm {
    padding: 16px;
    border: 0px solid #dde4ed;
    border-radius: 20px;
    background-color: #fff;
    max-width: 400px;
    width: 100%;
    text-align: center;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
    z-index: 99;
}

.LoginContainer .LoginForm img {
    margin-bottom: 28px;
    margin-top: 12px;
    height: 70px;
}

.LoginContainer .LoginForm input {
    margin-bottom: 12px;
    text-align: center;
}

.LoginContainer .LoginFormRegister {
    padding: 24px;
    margin-top: 24px;
    border-top: 1px solid #f3f3f3;
}