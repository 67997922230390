.bugdetail-actions-dropdown-priorities {

}

.bugdetail-actions-dropdown-priorities .dropdown .dropdown-toggle {
    border-radius: 5px;
    padding: 0px;
    width: 100%;
}

.bugdetail-actions-dropdown-priorities .dropdown .dropdown-toggle::after {
    position: absolute;
    right: 16px;
    top: calc(50% - 2px);
}

.bugdetail-actions-dropdown-priorities .dropdown .dropdown-menu {
    width: 100%;
}

.bugdetail-actions-dropdown-priorities .dropdown-item {
    padding: 0px;
}

.bugPriorityItem {
    width: 100%;
    padding: 12px;
    position: relative;
    text-align: left;
    line-height: 20px;
}

.bugPriorityItemIcon {
    font-size: 20px;
    margin-right: 16px;
    width: 20px;
    display: inline-block;
    text-align: center;
}