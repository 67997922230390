.bugdetail-actions-dropdown {

}

.bugdetail-actions-dropdown .dropdown .dropdown-toggle {
    border-radius: 5px;
    padding: 0px;
    width: 100%;
}

.bugdetail-actions-dropdown .dropdown .dropdown-toggle::after {
    position: absolute;
    right: 16px;
    top: calc(50% - 2px);
}

.bugdetail-actions-dropdown .dropdown .dropdown-menu {
    width: 100%;
}

.bugdetail-actions-dropdown .dropdown-item {
    padding: 0px;
}

.bugStatusItem {
    width: 100%;
    padding: 8px;
    position: relative;
    text-align: left;
    display: flex;
    align-items: center;
}

.bugStatusItem span {
    display: inline-block;
    line-height: 30px;
    height: 30px;
}