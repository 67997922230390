.createprojectbutton {
    max-width: 350px;
    display: flex;
    justify-items: center;
    flex-direction: column;
}

.createprojectbutton-btn {
    text-align: left;
    margin-top: 16px;
}

.createprojectbutton input {
    text-align: left;
    margin-top: 8px;
}

.createprojectbutton .subtitle-text {
    margin-bottom: 8px;
    margin-top: 0px;
}