body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
    font-size: 14px;
    font-family: proxima-nova, sans-serif;
    color: #33375A;
}

.btn-primary path {
  color: #fff;
}

.soft-green-bg {
  background-color: rgba(29, 191, 115, 0.05);
}

.soft-yellow-bg {
  background-color: rgba(236, 178, 45, 0.05);
}

.btn-primary {
    color: #fff;
    background-color: #3574F3;
    border-color: #3574F3;
    border: 0px;
    border-left: 1px solid #ced4da;
    font-weight: bold;
}

.rc-steps-item-finish .rc-steps-item-title:after {
  background-color: #3574F3 !important;
}

.btn-link {
  color: #3574F3;
}

.btn-link:hover {
  color: #135DEE;
}

a:hover {
  color: #135DEE;
}

.btn-primary:hover {
  background-color: #135DEE;
  border-color: #135DEE;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  background-color: #135DEE;
  border-color: #135DEE;
}

.shadow-card {
}

.box-content {
  max-width: 460px;
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #9caabd;
    background-color: #dde4ed;
    border: 0px;
    border-left: 1px solid #ced4da;
}

.nav-item {
    cursor: pointer;
}

a {
    color: #3574F3;
}

.rc-steps-item-finish .rc-steps-item-icon {
    border-color: #3574F3 !important;
}

.rc-steps-item-process .rc-steps-item-icon {
    background-color: #3574F3 !important;
    border-color: #3574F3 !important;
}

.row {
    margin-right: 0px;
    margin-left: 0px;
}

h1 {
    font-size: 26px;
    line-height: 43px;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #33375A;
    margin: 0px;
    padding: 0px;
}

.rc-steps-item-process .rc-steps-item-title {
    color: #33375A;
}

.boldtext {
    font-weight: bold;
    color: #33375A;
}

.subtitle-text {
    text-align: left;
    margin-bottom: 35px;
    margin-top: -10px;
}

.app {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.planUpgradeInfo {
  text-align: center;
  font-weight: bold;
}

.mainsidebar {
    width: 65px;
    color: #3574F3;
    background-color: #fff;
    height: 100vh;
}

.mainapp {
  width: calc(100% - 66px);
  height: 100vh;
  background-color: #F4F7FC;
}

.hide-sidebar .mainsidebar {
  display: none !important;
}

.hide-sidebar .mainapp {
  width: 100% !important;
}

.nav-tabs {
  background-color: #F4F7FC;
  border-bottom: 0px;
}

.nav-link {
  padding: 16px 24px !important;
  border: 0px !important;
  border-top: 2px solid transparent !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.nav-link.active {
  border-top: 2px solid #3574F3 !important;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-top: 2px solid #3574F3 !important;
  background-color: rgba(255, 255, 255, 0.4) !important;
}

.mainapp-full-width {
    width: 100%;
    overflow: inherit;
}

.form-control {
    border-width: 2px;
    border-color: #dde4ed;
    padding: 20px;
    padding-left: 16px;
    padding-right: 16px;
}

.dropdown .dropdown-toggle {
    border: 0px solid #dde4ed;
    background-color: #F4F7FC;
}

.dropdown-menu {
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  padding: 10px 0;
  background-color: #fff;
  box-shadow: 0 4px 14px 0 rgba(0,0,0,.2),0 0 0 1px rgba(0,0,0,.05);
}

.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #617084;
}

.dropdown-item.active span, .dropdown-item:active span {
    color: #fff;
    text-decoration: none;
    background-color: #617084;
}

.dropdown-item.active .bugPriorityItem span, .dropdown-item:active .bugPriorityItem span {
    color: #fff;
    text-decoration: none;
}

.dropdown .dropdown-toggle::after {
    color: #617084;
}

.dropdown.show .dropdown-toggle::after {
    color: #fff;
}

.dropdown-item.active .bugAssignedToItem div div, .dropdown-item:active .bugAssignedToItem div div {
    color: #fff;
    text-decoration: none;
}

.dropdown-item.active .bugPriorityItem span path, .dropdown-item:active .bugPriorityItem span path {
    color: #fff;
    text-decoration: none;
}

.dropdown-item.active .bugStatusItem span, .dropdown-item:active .bugStatusItem span {
    color: #fff;
    text-decoration: none;
}

.dropdown-menu {
    padding: 0px;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
    background-color: #F4F7FC;
    border-color: #F4F7FC;
    color: #617084;
}

.btn-secondary:not(:disabled):not(.disabled):active div div, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle div div {
  background-color: #F4F7FC;
  border-color: #F4F7FC;
  color: #617084;
}

.btn-secondary:not(:disabled):not(.disabled):active span, .btn-secondary:not(:disabled):not(.disabled).active span, .show > .btn-secondary.dropdown-toggle span {
    /*color: #fff;*/
}

.btn-secondary:not(:disabled):not(.disabled):active span path, .btn-secondary:not(:disabled):not(.disabled).active span path, .show > .btn-secondary.dropdown-toggle span path {
    /*color: #fff;*/
}

.Container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    background-color: #F5F7FA;
}

.BattlePage > .Container{
  background-color: transparent;
  background-color: initial;
}

.Container .FullWidthContainer {
  width: 100%;
  padding: 0px;
  padding-right: 0px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  height: 100vh;
  overflow: auto;
  background-color: #fff;
}

.Container .LeftSidebar {
    width: 280px;
    min-width: 280px;
    padding: 0px;
    padding-right: 0px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    height: calc(100vh);
    overflow: auto;
}

.pt-0 {
  padding-top: 0px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-0 {
  margin-top: 0px !important;
}

.MainContainerFull {
  width: 100%;
  padding: 0px;
  padding-right: 0px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
  border-radius: 0px;
  height: calc(100vh);
  overflow: auto;
  background-color: #fff;
}

.MainContainerFullInner {
  padding: 64px;
  max-width: 900px;
}

.MainContainerFullInnerFullWidth {
  padding: 64px;
}

.Container .LeftSidebarHeader {
  color: #33375A;
  font-size: 22px;
  font-weight: bold;
  padding: 16px;
  padding-bottom: 0px;
}

.LeftNavigationBar {
  background-color: #F5F7FA;
  width: 280px;
  min-width: 280px;
  height: 100vh;
}

.Container .MainContainer {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  padding: 0px;
  height: 100vh;
  overflow: auto;
  background-color: #fff;
}

.FullPageContainer {
    width: calc(100% - 64px);
    margin: 32px;
}

.dashboard-dialog-card {
    position: absolute;
    top: 127px;
    left: 50%;
    margin-left: -200px;
    padding: 32px;
    border: 0px solid #dde4ed;
    border-radius: 20px;
    background-color: #fff;
    width: 400px;
    text-align: center;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
    z-index: 99;
}

.dashboard-dialog-card-body {
    margin-bottom: 24px;
    margin-top: 8px;
}

.dashboard-dialog-card-header {
    font-size: 40px;
    display: block;
}

.codeblock {
    word-wrap: break-word;
    word-break: break-word;
    padding: 10px;
    margin: 1em 0;
    line-height: 1.65;
    border: 1px solid #EAEAEA;
    color: #33375A;
    background: #f8f8f8;
    overflow: auto;
    border-radius: 5px;
}

pre {
    word-wrap: break-word;
    word-break: break-word;
    padding: 10px;
    margin: 1em 0;
    line-height: 1.65;
    border: 1px solid #EAEAEA;
    color: #33375A;
    background: #f8f8f8;
    overflow: auto;
    border-radius: 5px;
}

.ml-8 {
  margin-left: 8px;
}

.mt-0 {
  margin-top: 0px !important;
}

.mw-550 {
  min-width: 550px;
}

.mt-16 {
    margin-top: 16px;
}

.mb-16 {
    margin-bottom: 16px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-8 {
    margin-top: 8px;
}

.mt-32 {
    margin-top: 32px;
}

.mb-32 {
  margin-bottom: 32px;
}

.m-32 {
    margin: 32px;
}

.p-32 {
    padding: 32px !important;
}

.mb-8 {
    margin-bottom: 8px;
}

h2.card-title {
    text-align: left;
    font-size: 18px;
    font-weight: normal;
    padding: 0px;
    margin: 0px;
    margin-bottom: 16px;
    line-height: 24px;
}

.CenterMainContainer {
    width: 90%;
    margin: auto;
    max-width: 780px;
}

.LeftContainer {
    width: 66%;
}

.RightContainer {
    width: 33%;
}

.LeftContainerEqual {
    width: 40%;
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 32px;
    padding-right: 8px;
}

.LeftContainerBattle {
  width: 50%;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 5%;
  padding-right: 5%;
}

.GiphyContainer {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100vh;
  width: calc(50% - 32px);
  background-position: center center;
  background-size: cover;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 32px;
  padding-right: 32px;
}

.RightContainerEqual {
    width: 50%;
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 8px;
    padding-right: 32px;
}

.Toastify__toast-container {
    z-index: 9999;
    position: fixed;
    padding: 4px;
    width: 320px;
    box-sizing: border-box;
    color: #fff; }
    .Toastify__toast-container--top-left {
      top: 1em;
      left: 1em; }
    .Toastify__toast-container--top-center {
      top: 1em;
      left: 50%;
      margin-left: -160px; }
    .Toastify__toast-container--top-right {
      top: 1em;
      right: 1em; }
    .Toastify__toast-container--bottom-left {
      bottom: 1em;
      left: 1em; }
    .Toastify__toast-container--bottom-center {
      bottom: 1em;
      left: 50%;
      margin-left: -160px; }
    .Toastify__toast-container--bottom-right {
      bottom: 1em;
      right: 1em; }
  
  @media only screen and (max-width: 480px) {
    .Toastify__toast-container {
      width: 100vw;
      padding: 0;
      left: 0;
      margin: 0; }
      .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
        top: 0; }
      .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
        bottom: 0; }
      .Toastify__toast-container--rtl {
        right: 0;
        left: auto;
        left: initial; } }
  
  .Toastify__toast {
    position: relative;
    min-height: 64px;
    box-sizing: border-box;
    margin-bottom: 1rem;
    padding: 8px;
    border-radius: 5px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    max-height: 800px;
    overflow: hidden;
    font-family: sans-serif;
    cursor: pointer;
    direction: ltr; }
    .Toastify__toast--rtl {
      direction: rtl; }
    .Toastify__toast--default {
      background: #fff;
      color: #aaa; }
    .Toastify__toast--info {
      background: #3498db; }
    .Toastify__toast--success {
      background: #3574F3; }
    .Toastify__toast--warning {
      background: #f1c40f; }
    .Toastify__toast--error {
      background: #e74c3c; }
    .Toastify__toast-body {
      margin: auto 0;
      -webkit-flex: 1 1;
              flex: 1 1; }
          .Toastify__toast-body {
              color: #fff !important;
          }
  
  @media only screen and (max-width: 480px) {
    .Toastify__toast {
      margin-bottom: 0; } }
  
  .Toastify__close-button {
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;
    opacity: 0.7;
    transition: 0.3s ease;
    -webkit-align-self: flex-start;
            align-self: flex-start; }
    .Toastify__close-button--default {
      color: #000;
      opacity: 0.3; }
    .Toastify__close-button:hover, .Toastify__close-button:focus {
      opacity: 1; }
  
  @-webkit-keyframes Toastify__trackProgress {
    0% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1); }
    100% {
      -webkit-transform: scaleX(0);
              transform: scaleX(0); } }
  
  @keyframes Toastify__trackProgress {
    0% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1); }
    100% {
      -webkit-transform: scaleX(0);
              transform: scaleX(0); } }
  
  .Toastify__progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    z-index: 9999;
    opacity: 0.7;
    background-color: rgba(255, 255, 255, 0.7);
    -webkit-transform-origin: left;
            transform-origin: left; }
    .Toastify__progress-bar--animated {
      -webkit-animation: Toastify__trackProgress linear 1 forwards;
              animation: Toastify__trackProgress linear 1 forwards; }
    .Toastify__progress-bar--controlled {
      transition: -webkit-transform .2s;
      transition: transform .2s;
      transition: transform .2s, -webkit-transform .2s; }
    .Toastify__progress-bar--rtl {
      right: 0;
      left: auto;
      left: initial;
      -webkit-transform-origin: right;
              transform-origin: right; }
    .Toastify__progress-bar--default {
      background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55); }
  
  @-webkit-keyframes Toastify__bounceInRight {
    from,
    60%,
    75%,
    90%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
              animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    from {
      opacity: 0;
      -webkit-transform: translate3d(3000px, 0, 0);
              transform: translate3d(3000px, 0, 0); }
    60% {
      opacity: 1;
      -webkit-transform: translate3d(-25px, 0, 0);
              transform: translate3d(-25px, 0, 0); }
    75% {
      -webkit-transform: translate3d(10px, 0, 0);
              transform: translate3d(10px, 0, 0); }
    90% {
      -webkit-transform: translate3d(-5px, 0, 0);
              transform: translate3d(-5px, 0, 0); }
    to {
      -webkit-transform: none;
              transform: none; } }
  
  @keyframes Toastify__bounceInRight {
    from,
    60%,
    75%,
    90%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
              animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    from {
      opacity: 0;
      -webkit-transform: translate3d(3000px, 0, 0);
              transform: translate3d(3000px, 0, 0); }
    60% {
      opacity: 1;
      -webkit-transform: translate3d(-25px, 0, 0);
              transform: translate3d(-25px, 0, 0); }
    75% {
      -webkit-transform: translate3d(10px, 0, 0);
              transform: translate3d(10px, 0, 0); }
    90% {
      -webkit-transform: translate3d(-5px, 0, 0);
              transform: translate3d(-5px, 0, 0); }
    to {
      -webkit-transform: none;
              transform: none; } }
  
  @-webkit-keyframes Toastify__bounceOutRight {
    20% {
      opacity: 1;
      -webkit-transform: translate3d(-20px, 0, 0);
              transform: translate3d(-20px, 0, 0); }
    to {
      opacity: 0;
      -webkit-transform: translate3d(2000px, 0, 0);
              transform: translate3d(2000px, 0, 0); } }
  
  @keyframes Toastify__bounceOutRight {
    20% {
      opacity: 1;
      -webkit-transform: translate3d(-20px, 0, 0);
              transform: translate3d(-20px, 0, 0); }
    to {
      opacity: 0;
      -webkit-transform: translate3d(2000px, 0, 0);
              transform: translate3d(2000px, 0, 0); } }
  
  @-webkit-keyframes Toastify__bounceInLeft {
    from,
    60%,
    75%,
    90%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
              animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    0% {
      opacity: 0;
      -webkit-transform: translate3d(-3000px, 0, 0);
              transform: translate3d(-3000px, 0, 0); }
    60% {
      opacity: 1;
      -webkit-transform: translate3d(25px, 0, 0);
              transform: translate3d(25px, 0, 0); }
    75% {
      -webkit-transform: translate3d(-10px, 0, 0);
              transform: translate3d(-10px, 0, 0); }
    90% {
      -webkit-transform: translate3d(5px, 0, 0);
              transform: translate3d(5px, 0, 0); }
    to {
      -webkit-transform: none;
              transform: none; } }
  
  @keyframes Toastify__bounceInLeft {
    from,
    60%,
    75%,
    90%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
              animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    0% {
      opacity: 0;
      -webkit-transform: translate3d(-3000px, 0, 0);
              transform: translate3d(-3000px, 0, 0); }
    60% {
      opacity: 1;
      -webkit-transform: translate3d(25px, 0, 0);
              transform: translate3d(25px, 0, 0); }
    75% {
      -webkit-transform: translate3d(-10px, 0, 0);
              transform: translate3d(-10px, 0, 0); }
    90% {
      -webkit-transform: translate3d(5px, 0, 0);
              transform: translate3d(5px, 0, 0); }
    to {
      -webkit-transform: none;
              transform: none; } }
  
  @-webkit-keyframes Toastify__bounceOutLeft {
    20% {
      opacity: 1;
      -webkit-transform: translate3d(20px, 0, 0);
              transform: translate3d(20px, 0, 0); }
    to {
      opacity: 0;
      -webkit-transform: translate3d(-2000px, 0, 0);
              transform: translate3d(-2000px, 0, 0); } }
  
  @keyframes Toastify__bounceOutLeft {
    20% {
      opacity: 1;
      -webkit-transform: translate3d(20px, 0, 0);
              transform: translate3d(20px, 0, 0); }
    to {
      opacity: 0;
      -webkit-transform: translate3d(-2000px, 0, 0);
              transform: translate3d(-2000px, 0, 0); } }
  
  @-webkit-keyframes Toastify__bounceInUp {
    from,
    60%,
    75%,
    90%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
              animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 3000px, 0);
              transform: translate3d(0, 3000px, 0); }
    60% {
      opacity: 1;
      -webkit-transform: translate3d(0, -20px, 0);
              transform: translate3d(0, -20px, 0); }
    75% {
      -webkit-transform: translate3d(0, 10px, 0);
              transform: translate3d(0, 10px, 0); }
    90% {
      -webkit-transform: translate3d(0, -5px, 0);
              transform: translate3d(0, -5px, 0); }
    to {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); } }
  
  @keyframes Toastify__bounceInUp {
    from,
    60%,
    75%,
    90%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
              animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 3000px, 0);
              transform: translate3d(0, 3000px, 0); }
    60% {
      opacity: 1;
      -webkit-transform: translate3d(0, -20px, 0);
              transform: translate3d(0, -20px, 0); }
    75% {
      -webkit-transform: translate3d(0, 10px, 0);
              transform: translate3d(0, 10px, 0); }
    90% {
      -webkit-transform: translate3d(0, -5px, 0);
              transform: translate3d(0, -5px, 0); }
    to {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); } }
  
  @-webkit-keyframes Toastify__bounceOutUp {
    20% {
      -webkit-transform: translate3d(0, -10px, 0);
              transform: translate3d(0, -10px, 0); }
    40%,
    45% {
      opacity: 1;
      -webkit-transform: translate3d(0, 20px, 0);
              transform: translate3d(0, 20px, 0); }
    to {
      opacity: 0;
      -webkit-transform: translate3d(0, -2000px, 0);
              transform: translate3d(0, -2000px, 0); } }
  
  @keyframes Toastify__bounceOutUp {
    20% {
      -webkit-transform: translate3d(0, -10px, 0);
              transform: translate3d(0, -10px, 0); }
    40%,
    45% {
      opacity: 1;
      -webkit-transform: translate3d(0, 20px, 0);
              transform: translate3d(0, 20px, 0); }
    to {
      opacity: 0;
      -webkit-transform: translate3d(0, -2000px, 0);
              transform: translate3d(0, -2000px, 0); } }
  
  @-webkit-keyframes Toastify__bounceInDown {
    from,
    60%,
    75%,
    90%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
              animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -3000px, 0);
              transform: translate3d(0, -3000px, 0); }
    60% {
      opacity: 1;
      -webkit-transform: translate3d(0, 25px, 0);
              transform: translate3d(0, 25px, 0); }
    75% {
      -webkit-transform: translate3d(0, -10px, 0);
              transform: translate3d(0, -10px, 0); }
    90% {
      -webkit-transform: translate3d(0, 5px, 0);
              transform: translate3d(0, 5px, 0); }
    to {
      -webkit-transform: none;
              transform: none; } }
  
  @keyframes Toastify__bounceInDown {
    from,
    60%,
    75%,
    90%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
              animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -3000px, 0);
              transform: translate3d(0, -3000px, 0); }
    60% {
      opacity: 1;
      -webkit-transform: translate3d(0, 25px, 0);
              transform: translate3d(0, 25px, 0); }
    75% {
      -webkit-transform: translate3d(0, -10px, 0);
              transform: translate3d(0, -10px, 0); }
    90% {
      -webkit-transform: translate3d(0, 5px, 0);
              transform: translate3d(0, 5px, 0); }
    to {
      -webkit-transform: none;
              transform: none; } }
  
  @-webkit-keyframes Toastify__bounceOutDown {
    20% {
      -webkit-transform: translate3d(0, 10px, 0);
              transform: translate3d(0, 10px, 0); }
    40%,
    45% {
      opacity: 1;
      -webkit-transform: translate3d(0, -20px, 0);
              transform: translate3d(0, -20px, 0); }
    to {
      opacity: 0;
      -webkit-transform: translate3d(0, 2000px, 0);
              transform: translate3d(0, 2000px, 0); } }
  
  @keyframes Toastify__bounceOutDown {
    20% {
      -webkit-transform: translate3d(0, 10px, 0);
              transform: translate3d(0, 10px, 0); }
    40%,
    45% {
      opacity: 1;
      -webkit-transform: translate3d(0, -20px, 0);
              transform: translate3d(0, -20px, 0); }
    to {
      opacity: 0;
      -webkit-transform: translate3d(0, 2000px, 0);
              transform: translate3d(0, 2000px, 0); } }
  
  .Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
    -webkit-animation-name: Toastify__bounceInLeft;
            animation-name: Toastify__bounceInLeft; }
  
  .Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
    -webkit-animation-name: Toastify__bounceInRight;
            animation-name: Toastify__bounceInRight; }
  
  .Toastify__bounce-enter--top-center {
    -webkit-animation-name: Toastify__bounceInDown;
            animation-name: Toastify__bounceInDown; }
  
  .Toastify__bounce-enter--bottom-center {
    -webkit-animation-name: Toastify__bounceInUp;
            animation-name: Toastify__bounceInUp; }
  
  .Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
    -webkit-animation-name: Toastify__bounceOutLeft;
            animation-name: Toastify__bounceOutLeft; }
  
  .Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
    -webkit-animation-name: Toastify__bounceOutRight;
            animation-name: Toastify__bounceOutRight; }
  
  .Toastify__bounce-exit--top-center {
    -webkit-animation-name: Toastify__bounceOutUp;
            animation-name: Toastify__bounceOutUp; }
  
  .Toastify__bounce-exit--bottom-center {
    -webkit-animation-name: Toastify__bounceOutDown;
            animation-name: Toastify__bounceOutDown; }
  
  @-webkit-keyframes Toastify__zoomIn {
    from {
      opacity: 0;
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
              transform: scale3d(0.3, 0.3, 0.3); }
    50% {
      opacity: 1; } }
  
  @keyframes Toastify__zoomIn {
    from {
      opacity: 0;
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
              transform: scale3d(0.3, 0.3, 0.3); }
    50% {
      opacity: 1; } }
  
  @-webkit-keyframes Toastify__zoomOut {
    from {
      opacity: 1; }
    50% {
      opacity: 0;
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
              transform: scale3d(0.3, 0.3, 0.3); }
    to {
      opacity: 0; } }
  
  @keyframes Toastify__zoomOut {
    from {
      opacity: 1; }
    50% {
      opacity: 0;
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
              transform: scale3d(0.3, 0.3, 0.3); }
    to {
      opacity: 0; } }
  
  .Toastify__zoom-enter {
    -webkit-animation-name: Toastify__zoomIn;
            animation-name: Toastify__zoomIn; }
  
  .Toastify__zoom-exit {
    -webkit-animation-name: Toastify__zoomOut;
            animation-name: Toastify__zoomOut; }
  
  @-webkit-keyframes Toastify__flipIn {
    from {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
              transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
      opacity: 0; }
    40% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
              transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in; }
    60% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
              transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
      opacity: 1; }
    80% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
              transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
    to {
      -webkit-transform: perspective(400px);
              transform: perspective(400px); } }
  
  @keyframes Toastify__flipIn {
    from {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
              transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
      opacity: 0; }
    40% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
              transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in; }
    60% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
              transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
      opacity: 1; }
    80% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
              transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
    to {
      -webkit-transform: perspective(400px);
              transform: perspective(400px); } }
  
  @-webkit-keyframes Toastify__flipOut {
    from {
      -webkit-transform: perspective(400px);
              transform: perspective(400px); }
    30% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
              transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
      opacity: 1; }
    to {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
              transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      opacity: 0; } }
  
  @keyframes Toastify__flipOut {
    from {
      -webkit-transform: perspective(400px);
              transform: perspective(400px); }
    30% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
              transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
      opacity: 1; }
    to {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
              transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      opacity: 0; } }
  
  .Toastify__flip-enter {
    -webkit-animation-name: Toastify__flipIn;
            animation-name: Toastify__flipIn; }
  
  .Toastify__flip-exit {
    -webkit-animation-name: Toastify__flipOut;
            animation-name: Toastify__flipOut; }
  
  @-webkit-keyframes Toastify__slideInRight {
    from {
      -webkit-transform: translate3d(110%, 0, 0);
              transform: translate3d(110%, 0, 0);
      visibility: visible; }
    to {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); } }
  
  @keyframes Toastify__slideInRight {
    from {
      -webkit-transform: translate3d(110%, 0, 0);
              transform: translate3d(110%, 0, 0);
      visibility: visible; }
    to {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); } }
  
  @-webkit-keyframes Toastify__slideInLeft {
    from {
      -webkit-transform: translate3d(-110%, 0, 0);
              transform: translate3d(-110%, 0, 0);
      visibility: visible; }
    to {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); } }
  
  @keyframes Toastify__slideInLeft {
    from {
      -webkit-transform: translate3d(-110%, 0, 0);
              transform: translate3d(-110%, 0, 0);
      visibility: visible; }
    to {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); } }
  
  @-webkit-keyframes Toastify__slideInUp {
    from {
      -webkit-transform: translate3d(0, 110%, 0);
              transform: translate3d(0, 110%, 0);
      visibility: visible; }
    to {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); } }
  
  @keyframes Toastify__slideInUp {
    from {
      -webkit-transform: translate3d(0, 110%, 0);
              transform: translate3d(0, 110%, 0);
      visibility: visible; }
    to {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); } }
  
  @-webkit-keyframes Toastify__slideInDown {
    from {
      -webkit-transform: translate3d(0, -110%, 0);
              transform: translate3d(0, -110%, 0);
      visibility: visible; }
    to {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); } }
  
  @keyframes Toastify__slideInDown {
    from {
      -webkit-transform: translate3d(0, -110%, 0);
              transform: translate3d(0, -110%, 0);
      visibility: visible; }
    to {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); } }
  
  @-webkit-keyframes Toastify__slideOutRight {
    from {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); }
    to {
      visibility: hidden;
      -webkit-transform: translate3d(110%, 0, 0);
              transform: translate3d(110%, 0, 0); } }
  
  @keyframes Toastify__slideOutRight {
    from {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); }
    to {
      visibility: hidden;
      -webkit-transform: translate3d(110%, 0, 0);
              transform: translate3d(110%, 0, 0); } }
  
  @-webkit-keyframes Toastify__slideOutLeft {
    from {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); }
    to {
      visibility: hidden;
      -webkit-transform: translate3d(-110%, 0, 0);
              transform: translate3d(-110%, 0, 0); } }
  
  @keyframes Toastify__slideOutLeft {
    from {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); }
    to {
      visibility: hidden;
      -webkit-transform: translate3d(-110%, 0, 0);
              transform: translate3d(-110%, 0, 0); } }
  
  @-webkit-keyframes Toastify__slideOutDown {
    from {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); }
    to {
      visibility: hidden;
      -webkit-transform: translate3d(0, 500px, 0);
              transform: translate3d(0, 500px, 0); } }
  
  @keyframes Toastify__slideOutDown {
    from {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); }
    to {
      visibility: hidden;
      -webkit-transform: translate3d(0, 500px, 0);
              transform: translate3d(0, 500px, 0); } }
  
  @-webkit-keyframes Toastify__slideOutUp {
    from {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); }
    to {
      visibility: hidden;
      -webkit-transform: translate3d(0, -500px, 0);
              transform: translate3d(0, -500px, 0); } }
  
  @keyframes Toastify__slideOutUp {
    from {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); }
    to {
      visibility: hidden;
      -webkit-transform: translate3d(0, -500px, 0);
              transform: translate3d(0, -500px, 0); } }
  
  .Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
    -webkit-animation-name: Toastify__slideInLeft;
            animation-name: Toastify__slideInLeft; }
  
  .Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
    -webkit-animation-name: Toastify__slideInRight;
            animation-name: Toastify__slideInRight; }
  
  .Toastify__slide-enter--top-center {
    -webkit-animation-name: Toastify__slideInDown;
            animation-name: Toastify__slideInDown; }
  
  .Toastify__slide-enter--bottom-center {
    -webkit-animation-name: Toastify__slideInUp;
            animation-name: Toastify__slideInUp; }
  
  .Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
    -webkit-animation-name: Toastify__slideOutLeft;
            animation-name: Toastify__slideOutLeft; }
  
  .Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
    -webkit-animation-name: Toastify__slideOutRight;
            animation-name: Toastify__slideOutRight; }
  
  .Toastify__slide-exit--top-center {
    -webkit-animation-name: Toastify__slideOutUp;
            animation-name: Toastify__slideOutUp; }
  
  .Toastify__slide-exit--bottom-center {
    -webkit-animation-name: Toastify__slideOutDown;
            animation-name: Toastify__slideOutDown; }

.payment-button-line {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.payment-button-line img {
    height: 24px;
    width: auto;
}

.payment-button-line-loading {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 20px;
}

.CardBoxBorder {
  padding: 32px;
  border: 0px solid #dde4ed;
  border-radius: 5px;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
  background-color: #fff;
}

.CardBoxBorder--topnav {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.CardBoxBorder--blue {
  color: #fff;
  background-color: #3574F3;
}

.CardBoxBorder--blue h1 {
  color: #fff;
}

.CardBoxBorder--blue .subtitle-text {
  color: #fff;
}

.center {
  text-align: center;
}

.bold {
  font-weight: bold;
}

.width-50 {
  width: 50%;
}

.center-table-cell-title {
  text-align: center !important;
  padding: 0px !important;
  margin: 0px !important;
}

.btn-lg, .btn-group-lg > .btn {
  font-size: 1.1rem;
}

.btn-white {
  background-color: #fff;
  font-weight: bold;
}

.bugdetails-modal-container {
  height: 100vh;
  border-radius: 0px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, .25) !important;
}

.invoice-line {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border: 2px solid #dde4ed;
  padding: 16px;
  margin-bottom: 8px;
  border-radius: 5px;
}

.center {
  text-align: center;
  width: 100%;
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}

.billingDidExpire {
  background-color: #4C6085;
  color: #fff;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 5px;
}

.billingDidExpire a {
  color: #fff;
  font-weight: bold;
}

.trialExpiredInterstelar {
  background-color: #fff;
  color: #3574F3;
  width: 100vw;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.trialExpiredInterstelarContainer {
  color: #33375A;
  margin-bottom: 16px;
}

.trialExpiredInterstelarContainer a {
  color: #3574F3;
  font-weight: bold;
}

.trialExpiredInterstelarContainer {
  max-width: 350px;
  text-align: center;
}

.externalCard {
  border: 2px solid #dde4ed;
  background-color: #fff;
  cursor: pointer;
  border-radius: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 16px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
}

.externalCard > div {
  padding: 24px;
}

.externalCard .title {
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
}

.externalCard .type {
  color: rgb(6, 147, 227);
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  line-height: 30px;
}

.externalCard .type--feedback {
  color: rgb(142, 209, 252);
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  line-height: 30px;
}

.loadingOverlayExternal {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: none;
  background-color: #fff;
  z-index: 99999;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.loadingOverlayExternal--active {
  display: -webkit-flex;
  display: flex;
}

.bugsListLoading {
  padding: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.bugpoints-important {
  color: #ff0000;
}

.bugpoints-medium {
  color: #f7941d;
}

.bugpoints-new {
  
}

.project-quick-selection-container {
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 12;
}

.project-selector-top {
  padding: 20px;
  position: relative;
}

.project-selector-top select {
  border:none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -ms-appearance: none;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
  border: solid 1px #fff;
  color: #33375A;
  line-height: 34px;
  padding: 0px 10px;
  text-align: center;
  min-width: 130px;
  font-weight: bold;
  cursor: pointer;
}

.project-selector-top svg {
  position: absolute;
  top: 31px;
  right: 32px;
}
.LoginContainer {
    width: 100vw;
    height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 20px;
}

.LoginContainer .LoginForm {
    padding: 16px;
    border: 0px solid #dde4ed;
    border-radius: 20px;
    background-color: #fff;
    max-width: 400px;
    width: 100%;
    text-align: center;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
    z-index: 99;
}

.LoginContainer .LoginForm img {
    margin-bottom: 28px;
    margin-top: 12px;
    height: 70px;
}

.LoginContainer .LoginForm input {
    margin-bottom: 12px;
    text-align: center;
}

.LoginContainer .LoginFormRegister {
    padding: 24px;
    margin-top: 24px;
    border-top: 1px solid #f3f3f3;
}
.login-signup-title {
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 20px;
}
.dashboard-loading-container {
    position: absolute;
    top: calc(50% - 2px);
    left: calc(50% - 50px);
}

.Card {
    padding: 16px;
    border: 0px solid #dde4ed;
    background-color: #fff;
    margin: 32px;
    margin-left: 0px;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
    color: #fff;
    position: relative;
}

.Card--icon {
    font-size: 60px;
    line-height: 60px;
    position: absolute;
    top: 50%;
    left: 0%;
    text-align: center;
    width: 100%;
    margin-top: -40px;
    color: #fff;
}

.Card--blue .Card--subtitle {
    color: #fff;
}

.Card--blue .Card--subtitle b {
    color: #fff;
}

.Card--subtitle {
    text-align: center;
    position: absolute;
    bottom: 24px;
    left: 0px;
    width: 100%;
}

.Card--WithBorderBottom {
    border-bottom: 4px solid #0941CD;
}

.Card--withicon {
    min-height: 220px;
}

.Card--withimage {
    min-height: 250px;
}

.Card--green {
    background-color: #50E3C2;
}

.Card--blue {
    background-image: linear-gradient(to right, #3574F3, #3574F3);
}

.Card--title {
    font-size: 28px;
    text-align: center;
}

.Card--body {
    text-align: center;
}

.Card--blue .Card--title {
    color: #fff;
}

.Card--blue .Card--body {
    color: #fff;
}

.Card--blue .Card--body b {
    color: #fff;
}
.projects-list {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.projects-list-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    background-color: #fff;
    cursor: pointer;
    border-radius: 5px;
}

.projectheader {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding-bottom: 16px;
}

.projects-list-description {
    margin-bottom: 32px;
    margin-top: -16px;
}
.projectcard {
    padding: 24px;
    border: 2px solid #dde4ed;
    background-color: #fff;
    cursor: pointer;
    border-radius: 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: calc(33% - 32px);
    margin-right: 16px;
    margin-bottom: 16px;
    height: 200px;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
}

.projectcard:hover {
    border: 2px solid #3574F3;
}

.projectcard .title {
    font-size: 18px;
    font-weight: bold;
    line-height: 18px;
}

.projectcard .titleid {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    flex-grow: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}

.projectcard .titleid div {
    font-size: 14px;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    line-height: 30px;
    color: #999;
    font-size: 14px;
}

.projectcard:hover .title {
    color: #3574F3;
}

.trialInfo {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.trialInfo span:first-child {
    display: block;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    text-align: left;
}

.trialInfo span {
    display: block;
    color: #4C6085;
    font-size: 14px;
    line-height: 14px;
    text-align: left;
}

.trialInfo span time {
    color: #4C6085;
}

.planInfo {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.planInfo span {
    color: #3574F3;
    font-size: 16px;
    line-height: 16px;
    font-weight: bold;
    line-height: 30px;
    text-transform: capitalize;
}

.createprojectbutton {
    max-width: 350px;
    display: -webkit-flex;
    display: flex;
    justify-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.createprojectbutton-btn {
    text-align: left;
    margin-top: 16px;
}

.createprojectbutton input {
    text-align: left;
    margin-top: 8px;
}

.createprojectbutton .subtitle-text {
    margin-bottom: 8px;
    margin-top: 0px;
}
.LeftNavigationBar {
  padding-left: 30px;
  padding-top: 50px;
}

.StepsContainer {
}

.StepsHeader {
  margin-bottom: 30px;
  font-weight: bold;
}

.ActiveStep {
  color: 'blue';
  cursor: pointer;
}

.ButtonContainer {
  margin-top: 30px;
}

.ThankYouHeader {
  margin-top: 20px;
}

.ThankYouDesc {
  margin-top: 10px;
}

.integration-token-viewer-code {
  display: block;
  padding: 20px;
  background: #ececec;
  color: #000;
  border-radius: 8px;
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: center;
  font-size: 18px;
}

.IntegrationCardNew {
  padding: 32px;
  border-radius: 5px;
  border: 2px solid #dde4ed;
  background-color: #fff;
  margin-left: -15px;
  margin-top: 32px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: relative;
  overflow: visible;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
}

.IntegrationCard {
  padding: 32px;
  border: 0px solid #dde4ed;
  border-radius: 5px;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  margin-left: -15px;
  margin-top: 32px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: relative;
  overflow: visible;
}

.IntegrationCardLogo {
  width: 30px;
  height: 30px;
  padding: 6px;
  object-fit: contain;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
  border-radius: 45px;
  top: -10px;
  right: -10px;
}

.IntegrationCardContent {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 8px;
}

.IntegrationCardContentTitle {
  line-height: 20px;
  font-weight: bold;
  color: #33375a;
}

.sdk-container {
  max-width: 900px;
}

.CardBoxBorder--Modal {
    width: 90vw;
    max-width: 650px;
}

.programingLanguageSelection {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding-top: 16px;
}

.programingLanguageSelection button:first-of-type {
    margin-right: 6px;
}

.programingLanguageSelection .btn-primary {
    padding: 0.55rem 0.75rem;
}

.success-panel .div {
    text-align: center;
    font-size: 20px;
}

.success-panel .boldtext {
    text-align: center;
    font-size: 22px;
}

.IntegrationModalBody {
    padding: 16px 0px;
}

.IntegrationModalBodyButtons {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.success-panel .IntegrationModalBodyButtons {
    -webkit-justify-content: center;
            justify-content: center;
}
.CardBoxBorder--Modal {
    width: 90vw;
    max-width: 650px;
}

.programingLanguageSelection {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding-top: 16px;
}

.programingLanguageSelection button:first-of-type {
    margin-right: 6px;
}

.programingLanguageSelection .btn-primary {
    padding: 0.55rem 0.75rem;
}

.success-panel .div {
    text-align: center;
    font-size: 20px;
}

.success-panel .boldtext {
    text-align: center;
    font-size: 22px;
}

.IntegrationModalBody {
    padding: 16px 0px;
}

.IntegrationModalBodyButtons {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.success-panel .IntegrationModalBodyButtons {
    -webkit-justify-content: center;
            justify-content: center;
}

.CardBoxBorder--Modal {
    width: 90vw;
    max-width: 650px;
}

.programingLanguageSelection {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding-top: 16px;
}

.programingLanguageSelection button:first-of-type {
    margin-right: 6px;
}

.programingLanguageSelection .btn-primary {
    padding: 0.55rem 0.75rem;
}

.success-panel .div {
    text-align: center;
    font-size: 20px;
}

.success-panel .boldtext {
    text-align: center;
    font-size: 22px;
}

.IntegrationModalBody {
    padding: 16px 0px;
}

.IntegrationModalBodyButtons {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.success-panel .IntegrationModalBodyButtons {
    -webkit-justify-content: center;
            justify-content: center;
}
.MainContainerFullInner {
  max-width: 100% !important;
}

.stateColorPickerPreview {
  width: 42px;
  height: 42px;
  position: relative;
  cursor: pointer;
}

.stateColorPickerPreviewPicker {
  position: absolute;
  top: 40px;
  left: 0px;
  z-index: 9999;
}

.stateColorPickerPreviewColor {
  width: 100%;
  height: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.state-item-bubble {
  border: 2px solid #dde4ed;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.04);
  padding: 8px;
  margin: 12px;
  margin-right: 16px;
  margin-left: 0px;
  margin-top: 0px;
  border-radius: 5px;
  background-color: #fff;
  position: relative;
  cursor: pointer;
  text-align: left;
  font-weight: bold;
  padding-right: 16px;
  padding-left: 16px;
}

.bugStatusBubbleState {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 12px;
  height: 12px;
  border-radius: 10px;
  z-index: 999;
}

.buttons {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: 15px;
  margin-top: 8px;
}

.modal-buttons {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.integrations-container {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
  margin-right: 0;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.buttons h2 {
  margin-bottom: 15px;
}

.buttons p {
  margin-bottom: 8px;
}

.integration-card {
  position: relative;
  padding: 25px;
  padding-top: 30px;
  margin-bottom: 40px;
  -webkit-flex-basis: 400px;
          flex-basis: 400px;
  margin-right: 50px;
  box-shadow: 5px 3px 10px #cacaca;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.ribbon {
  width: 120px;
  height: 120px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  /* z-index: -1; */
  content: '';
  display: block;
  border: 5px solid #23912c;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 170px;
  padding: 9px 0;
  background-color: #2cad37;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 600 13px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

.ribbon-top-right {
  top: -10px;
  right: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -15px;
  top: 35px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.configErrorText {
  margin-bottom: 0;
  position: absolute;
  bottom: 10px;
  color: red;
  font-size: 0.9em;
  text-align: center;
  -webkit-align-self: center;
          align-self: center;
}

.ConfigureIntegrationModal {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch;
  padding: 50px;
  min-width: 700px;
  z-index: 5;
}

.field-container {
  display: block;
  margin-bottom: 50px;
}

.modal-buttons {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
  position: relative;
  bottom: 0;
  /* justify-content: flex-end; */
}

.modal-buttons button {
  margin-left: 10px;
  min-width: 80px;
}


.ProjectListPage {
    background-color: #F4F7FC;
    width: 100%;
}
.buglist {
    border: 0px solid #dde4ed;
}
.bugcontainer {
    width: 100%;
    height: calc(100% - 107px);
}
.buglist-header-title-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    width: 100%;
}
.buglist-header-title-container-new {
    padding-top: 6px;
}
.new-bug-modal {
    background-color: #fff;
    border-radius: 5px;
    padding: 32px;
    width: 400px;
}
.add-button {
    border-radius: 5px;
    background-color: #3574F3;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
    border: solid 1px #3574F3;
    color: #fff;
}
.add-button path {
    color: #fff;
}
.add-button:hover path {
    color: #fff;
}
.buglistHeader {
    height: 107px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 2px solid #EBECF1;
    text-align: center;
    line-height: 47px;
    font-weight: bold;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding-left: 16px;
    padding-right: 16px;
}
.buglistHeaderTitle {
    color: #33375A;
    font-size: 22px;
    font-weight: bold;
    padding: 16px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
}
.buglistHeaderFilter {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    width: 100%;
    padding: 0px;
}
.bug {
    cursor: pointer;
    padding: 16px;
    position: relative;
    padding-bottom: 0px;
    border-bottom: 1px solid #dde4ed99;
    border-right: 0px solid #dde4ed99;
}
.bug:hover {
    background-color: #fff !important;
}
.bug:last-child {
    border-bottom: none;
}
.bugTitle {
    font-weight: bold;
    line-height: 22px;
    color: #33375A;
}
.bugDescription {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    height: 22px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.BoardColumn .bugDescription {
    margin-top: 0px;
    margin-bottom: 0px;
}
.bugStatusBubbleInlineBig {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 100%;
    margin-left: 8px;
    margin-right: 16px;
}
.bugStatusBubbleInline {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    margin-right: 4px;
}
.bugStatusBubble {
    position: absolute;
    top: 4px;
    bottom: 4px;
    left: 4px;
    width: 4px;
    border-radius: 10px;
}
.bugStorypointContainer {
    padding-top: 0px;
}
.bugStorypointContainer span {
    font-size: 12px;
}
.bugStorypointIcon {
    padding-right: 4px;
}
.bugTitleContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}
.bugItemDate time {
    font-size: 12px;
    line-height: 22px;
}
.bugStateContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding-bottom: 8px;
}
.BoardColumn .bugStateContainer {
    border-bottom: 0px solid #dde4ed99;
    padding-bottom: 0px;
}
.no-reports-yet-tutorial {
    text-align: center;
    padding: 16px;
}
.no-reports-yet-tutorial-header {
    font-weight: bold;
}
.no-reports-yet-tutorial-body {
    margin-top: 8px;
    margin-bottom: 12px;
}
.dropzone {
    height: 100px;
    border: 2px dotted #eee;
    border-radius: 5px;
    margin-bottom: 16px;
    padding: 16px;
    width: 100%;
}
.dropzone p {
    text-align: center;
    font-weight: bold;
}
.buglist-filter-dropdown {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 100%;
}

.buglist-filter-dropdown .dropdown {
    width: calc(50% - 3px);
}

.buglist-filter-dropdown .dropdown:first-of-type {
    margin-right: 6px;
}

.buglist-filter-dropdown .dropdown .dropdown-toggle {
    border-radius: 5px;
    padding: 0px;
    width: 100%;
    margin-bottom: 2.5px;
    background-color: #fff;
}

.buglist-filter-dropdown .dropdown .dropdown-toggle::after {
    position: absolute;
    right: 8px;
    top: calc(50% - 2px);
}

.buglist-filter-dropdown .dropdown .dropdown-menu {
    width: 280px;
}

.buglist-filter-dropdown .dropdown-toggle .dropdown-item {
    padding: 0px;
}

.buglist-filter-dropdown .dropdown-toggle .user-item-list {
    padding: 2px;
    padding-left: 10px;
    padding-right: 30px;
    line-height: 21px;
}

.buglist-filter-dropdown .dropdown-toggle .user-item-list-img-loading {
    width: 17px;
    height: 17px;
    margin-top: 2px;
}

.buglist-filter-dropdown .dropdown-toggle img {
    width: 17px;
    height: 17px;
    margin-top: 2px;
}

.buglist-filter-dropdown .dropdown-toggle .bugAssignedToItem {
    padding: 0px;
}

.bugListFilterItem {
    width: 100%;
    padding: 2px;
    padding-left: 20px;
    padding-right: 30px;
    position: relative;
    text-align: left;
}
.user-item-list {
    padding: 3px;
    margin: 0px;
    margin-right: 0px;
    margin-left: 0px;
    position: relative;
    cursor: pointer;
    text-align: left;
    display: -webkit-flex;
    display: flex;
    line-height: 30px;
}

.user-item-list img {
    height: 30px;
    border-radius: 15px;
    margin-right: 11px;
}

.user-item-list-img-loading {
    display: inline-block;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    margin-right: 8px;
    background-color: #fff !important;
    border: 1px solid #dde4ed;
}
.user-item-small {
    border: 0px solid #dde4ed;
    padding: 0px;
    margin: 0px;
    border-radius: 8px;
    background-color: transparent;
    position: relative;
    cursor: pointer;
    text-align: left;
    font-weight: bold;
}

.user-item-small img {
    height: 16px;
    border-radius: 8px;
}

.user-item-small-img-loading {
    display: inline-block;
    height: 16px;
    width: 16px;
    border-radius: 8px;
    background-color: #ddd;
}
.bugdetail {
    background-color: #fff;
    width: 100%;
    height: calc(100vh - 64px);
    padding: 16px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.bugdetail-nobugselected {
    text-align: center;
    width: 100%;
    min-height: calc(100vh - 34px);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.bugdetailtabs-container {
    margin-top: 16px;
}

.bugdetail-main {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    padding: 0px;
    padding-right: 32px;
    overflow: auto;
}

.bugdetail-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 100%;
}

.bugdetail-header {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 100%;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.bugdetail-reportedBy {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    line-height: 32px;
    font-size: 14px;
    color: #3574FA;
}

.bugdetaill-createdAt time {
    line-height: 32px;
    font-size: 14px;
}

.bugdetail-reportedBy-profile {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: #F4F7FC;
    border: 1px solid #3574FA;
    color: #3574FA;
    margin-right: 8px;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    font-size: 12px;
    line-height: 32px;
}

.bugdetail-main-data {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 100%;
    padding-top: 24px;
}

.bugdetail-screenshot-container-none {
    background-color: #F4F7FC;
    border-radius: 5px;
}

.bugdetail-screenshot-container {
    width: 10vw;
    min-width: 10vw;
    height: auto;
    margin-right: 16px;
}

.bugdetail-screenshot {
    width: 100%;
    height: auto;
    border: 1px solid #dde4ed;
    background-color: #eee;
    border-radius: 5px;
    cursor: pointer;
}

.bugdetail-description {
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

.bugdetail-right-sidebar {
    width: 280px;
    min-width: 280px;
    margin: 6px;
    padding-bottom: 0px;
}

.bugdetail-actions {
    width: 280px;
    min-width: 280px;
    margin-bottom: 16px;
    padding-bottom: 0px;
}

.bugdetail-actions-inner {
    padding: 16px;
    border: 1px solid #dde4ed;
    border-radius: 5px;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
}

.bugdetail-actions-header {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
}

.bugdetail-actions-header b {
    color: #33375A;
}

.bugdetail-actions-header-actions {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
}

.bugdetail-actions-header-actions .bugdetail-actions-header-action {
    cursor: pointer;
    padding: 4px;
    width: 30px;
    height: 30px;
    text-align: center;
}

.bugdetail-actions-header-actions svg path {
    color: #bcc4cc
}

.bugdetail-actions-header-actions:hover svg path {
    color: #3574F3
}

.bugdetail-bug-title {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 16px;
    color: #33375A;
}

.bugdetail-bug-meta-header-external {
    font-size: 15px;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 8px;
    color: #33375A;
}

.bugdetail-bug-meta-header {
    font-size: 15px;
    font-weight: bold;
    margin-top: 24px;
    margin-bottom: 16px;
    color: #33375A;
}

.bugdetail-bug-meta {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.bugdetail-bug-meta-card {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    width: 50%;
    margin-bottom: 16px;
}

.bugdetail-bug-meta-card-icon {
    border: 1px solid #dde4ed;
    border-radius: 5px;
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.bugdetail-bug-meta-card-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    height: 28px;
}

.bugdetail-bug-meta-card-title {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 12px;
    padding-left: 12px;
}

.bugdetail-bug-meta-card-data {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    padding-left: 12px;
    padding-right: 12px;
    word-break: break-all;
}

.callstack-info-container {
    word-break: break-all;
}

.callstack-info-container-title {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 16px;
}
.featureInProgress {
    width: 100%;
    padding: 10px;
    background-color: #3574F3;
    border-radius: 5px;
    margin-bottom: 20px;
    color: #fff;
    text-align: center;
}

.bug-detail-no-data-how-to {
    padding: 32px;
    text-align: center;
    font-size: 16px;
}

.bug-detail-no-data-how-to a {
    font-size: 16px;
}

.navitem-container {
    background-color: #F4F7FC;
    padding: 32px;
    padding-bottom: 0px;
    padding-top: 52px;
    border-bottom: 2px solid #EBECF1;
}

.bugdetails-modal-container .navitem-container {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.tab-content {
    padding: 32px;
    max-height: calc(100vh - 107px);
    overflow: auto;
}
.bugdetail-actions-dropdown {

}

.bugdetail-actions-dropdown .dropdown .dropdown-toggle {
    border-radius: 5px;
    padding: 0px;
    width: 100%;
}

.bugdetail-actions-dropdown .dropdown .dropdown-toggle::after {
    position: absolute;
    right: 16px;
    top: calc(50% - 2px);
}

.bugdetail-actions-dropdown .dropdown .dropdown-menu {
    width: 100%;
}

.bugdetail-actions-dropdown .dropdown-item {
    padding: 0px;
}

.bugStatusItem {
    width: 100%;
    padding: 8px;
    position: relative;
    text-align: left;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.bugStatusItem span {
    display: inline-block;
    line-height: 30px;
    height: 30px;
}
.bugdetail-actions-dropdown-assignedto {

}

.bugdetail-actions-dropdown-assignedto .dropdown .dropdown-toggle {
    border-radius: 5px;
    padding: 0px;
    width: 100%;
}

.bugdetail-actions-dropdown-assignedto .dropdown .dropdown-toggle::after {
    position: absolute;
    right: 16px;
    top: calc(50% - 2px);
}

.bugdetail-actions-dropdown-assignedto .dropdown .dropdown-menu {
    width: 100%;
}

.bugdetail-actions-dropdown-assignedto .dropdown-item {
    padding: 0px;
}

.bugAssignedToItem {
    width: 100%;
    padding: 5px;
    position: relative;
    text-align: left;
}
.bugdetail-actions-dropdown-priorities {

}

.bugdetail-actions-dropdown-priorities .dropdown .dropdown-toggle {
    border-radius: 5px;
    padding: 0px;
    width: 100%;
}

.bugdetail-actions-dropdown-priorities .dropdown .dropdown-toggle::after {
    position: absolute;
    right: 16px;
    top: calc(50% - 2px);
}

.bugdetail-actions-dropdown-priorities .dropdown .dropdown-menu {
    width: 100%;
}

.bugdetail-actions-dropdown-priorities .dropdown-item {
    padding: 0px;
}

.bugPriorityItem {
    width: 100%;
    padding: 12px;
    position: relative;
    text-align: left;
    line-height: 20px;
}

.bugPriorityItemIcon {
    font-size: 20px;
    margin-right: 16px;
    width: 20px;
    display: inline-block;
    text-align: center;
}
.comments {
    overflow: auto;
}

.comments-no-comments {
    text-align: center;
    padding: 16px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.comment-item {
    padding: 8px;
    margin-top: 8px;
    border-radius: 0px;
    background-color: #fff;
    border-bottom: 1px solid #dde4ed;
    position: relative;
    cursor: pointer;
    text-align: left;
}

.comment-item div {
    display: -webkit-flex;
    display: flex;
}

.comment-item-name {
    font-weight: bold;
    margin-right: 4px;
}

.comment-item img {
    height: 20px;
    border-radius: 10px;
    margin-right: 4px;
}

.comment-item-img-loading {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 15px;
    margin-right: 4px;
    background-color: #ddd;
}

.comment-item-createdAt time {
    font-size: 12px;
    opacity: 0.5;
}
.comment-creation {
    margin-top: 32px;
}
.bugdetail-description textarea {
    width: 100%;
    min-height: 160px;
}

.bugdetail-description {
    cursor: pointer;
}

.bugdetail-bug-meta-card-data-oneliner {
    max-height: 24px;
    overflow: scroll;
}
.buglistSelector {
    width: 400px;
    background-color: #F4F7FC;
    padding: 16px;
    border-radius: 8px;
}
.steps-to-reproduce {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-bottom: 6px;
}

.steps-to-reproduce-icon-container-inner {
    background-color: #F4F7FC;
    padding: 8px;
    margin-right: 8px;
    margin-top: 8px;
    border-radius: 5px;
}

.steps-to-reproduce-icon {
    width: 17px;
    height: 15px;
    font-size: 9px;
    line-height: 15px;
    background-color: #617084;
    border-radius: 3px;
    text-align: center;
}

.steps-to-reproduce-icon svg path {
    color: #fff;
}

.steps-to-reproduce-header {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-top: 7px;
    min-width: 90px;
}

.steps-to-reproduce-header-title {
    font-weight: bold;
    line-height: 17px;
    color: #33375A;
}

.steps-to-reproduce-header-date {
    line-height: 17px;
    font-size: 12px;
}

.steps-to-reproduce-header-date time {
    line-height: 17px;
    font-size: 12px;
}

.steps-to-reproduce-body {
    word-wrap: break-word;
    padding-top: 5px;
    padding-right: 10px;
}


.console-log {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-bottom: 6px;
}

.console-log-icon-container-inner {
    background-color: #F4F7FC;
    padding: 8px;
    margin-right: 8px;
    margin-top: 8px;
    border-radius: 5px;
}

.console-log-icon {
    width: 17px;
    height: 15px;
    font-size: 9px;
    line-height: 15px;
    background-color: #617084;
    border-radius: 3px;
    text-align: center;
}

.console-log-icon svg path {
    color: #fff;
}

.console-log-header {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-top: 7px;
    min-width: 90px;
}

.console-log-header-title {
    font-weight: bold;
    line-height: 17px;
    color: #33375A;
}

.console-log-header-date {
    line-height: 17px;
    font-size: 12px;
}

.console-log-header-date time {
    line-height: 17px;
    font-size: 12px;
}

.console-log-body {
    word-wrap: break-word;
    padding-top: 5px;
    padding-right: 10px;
}
.settings-tab-item-hero {
    padding: 8px;
    padding-left: 32px;
    font-size: 22px;
    line-height: 22px;
    font-weight: bold;
    color: #33375A;
    padding-top: 44px;
    padding-bottom: 16px;
}

.settings-tab-item {
    padding: 8px;
    padding-left: 32px;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    color: #33375A;
}

.settings-tab-item-spacer {
    width: 200px;
    height: 1px;
    background-color: #bcc4cc;
    margin-bottom: 35px;
    margin-top: 35px;
    margin-left: 24px;
    opacity: 0.3;
}

.settings-tab-item-icon path {
    color: #33375A;
}

.settings-tab-item-icon {
    width: 30px;
}

.settings-tab-item:hover {
    color: #135DEE;
}

.settings-tab-item:hover path {
    color: #135DEE;
}

.settings-tab-item-active {
    color: #3574F3;
}

.settings-tab-item-active path {
    color: #3574F3;
}

.ProjectPageSettings {
    padding-top: 70px;
}

.MainContainerFullHero {
    background-color: #F5F7FA;
    border-radius: 0px;
    padding: 40px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-content: center;
            align-content: center;
}

.MainContainerFullHeroTitle {
    font-size: 32px;
    line-height: 50px;
    color: #33375A;
    font-weight: bold;
    padding-right: 35px;
    margin-right: 35px;
    border-right: 1px solid rgba(0,0,0,.1);
}

.MainContainerFullHeroIcon {
    font-size: 30px;
    margin-right: 15px;
    height: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.MainContainerFullHeroIcon path {
    color: #3574F3;
}

.MainContainerFullHeroBody {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 50px;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
}
.project-members-list {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-bottom: 16px;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.project-members-list-user-item {
    position: relative;
}

.project-members-list-user-item-remove {
    position: absolute;
    top: -7px;
    right: 8px;
    background-color: #e1e6eb;
    width: 18px;
    height: 18px;
    border-radius: 9px;
    box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.08);
    cursor: pointer;
    line-height: 14px;
    padding: 2px;
    text-align: center;
}

.project-members-list-user-item-remove path {
    color: #617084;
}

.invited-email-body {
    border: 1px solid #dde4ed;
    box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.04);
    padding: 10px;
    margin: 10px;
    margin-right: 16px;
    margin-left: 0px;
    border-radius: 8px;
    background-color: #fff;
    position: relative;
    cursor: pointer;
    text-align: left;
    font-weight: bold;
    padding-right: 16px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.invited-email-body-image {
    height: 30px;
    border-radius: 15px;
    margin-right: 8px;
}

.invited-email-body-cont {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.invited-email-body-main {
    font-weight: bold;
    display: block;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 17px;
}

.invited-email-body-sub {
    font-weight: 500;
    font-size: 11px;
    line-height: 12px;
    display: block;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
}

.members-nooneinvited {
    text-align: center;
    border: 1px solid #dde4ed;
    box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.04);
    padding: 10px;
    margin: 10px;
    margin-right: 16px;
    margin-left: 0px;
    border-radius: 8px;
    background-color: #fff;
    position: relative;
    cursor: pointer;
    text-align: left;
    font-weight: bold;
    text-align: center;
}

.project-board-state-list {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-bottom: 16px;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.project-board-state-list-user-item {
    position: relative;
    width: 100%;
}

.project-board-state-list-user-item .state-item-bubble {
    padding: 10px;
    padding-right: 16px;
    padding-left: 38px;
    margin-right: 0px;
}

.project-board-state-list-user-item-remove {
    position: absolute;
    top: -7px;
    right: -8px;
    background-color: #e1e6eb;
    width: 18px;
    height: 18px;
    border-radius: 9px;
    box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.08);
    cursor: pointer;
    line-height: 14px;
    padding: 2px;
    text-align: center;
}

.project-board-state-list-user-item-remove path {
    color: #617084;
}
.user-item {
    border: 2px solid #dde4ed;
    box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.04);
    padding: 8px;
    margin: 10px;
    margin-right: 16px;
    margin-left: 0px;
    border-radius: 8px;
    background-color: #fff;
    position: relative;
    cursor: pointer;
    text-align: left;
    font-weight: bold;
    padding-right: 16px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.user-item img {
    height: 30px;
    border-radius: 15px;
    margin-right: 8px;
}

.user-item-img-loading {
    display: inline-block;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    margin-right: 8px;
    background-color: #ddd;
}

.user-item-cont {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.user-item-sub {
    font-weight: 500;
    font-size: 11px;
    line-height: 12px;
    display: block;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
}

.user-item-main {
    font-weight: bold;
    display: block;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
    line-height: 17px;
}
.project-delete-link {
    padding-top: 32px;
}

.project-delete-link a {
    color: #999;
}
.stateColorPickerPreview {
    width: 42px;
    height: 42px;
    position: relative;
    cursor: pointer;
}

.stateColorPickerPreviewPicker {
    position: absolute;
    top: 40px;
    left: 0px;
    z-index: 9999;
}

.stateColorPickerPreviewColor {
    width: 100%;
    height: 100%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.state-item-bubble {
    border: 2px solid #dde4ed;
    box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.04);
    padding: 8px;
    margin: 12px;
    margin-right: 16px;
    margin-left: 0px;
    margin-top: 0px;
    border-radius: 5px;
    background-color: #fff;
    position: relative;
    cursor: pointer;
    text-align: left;
    font-weight: bold;
    padding-right: 16px;
    padding-left: 16px;
}
.bugStatusBubbleState {
    position: absolute;
    top: 16px;
    left: 16px;
    width: 12px;
    height: 12px;
    border-radius: 10px;
    z-index: 999;
}
.project-plans {
    text-align: center;
}

.project-plans td {
    width: 33.3333 !important;
}

.current-plan {
    line-height: 34px;
    font-weight: bold;
    color: #3574F3;
    border: #3574F3 solid 1px;
    border-radius: 5px;
    padding: 0px 20px;
    width: 100%;
}

.next-payment {
    margin-top: 8px;
    font-style: italic;
}

.loading-plan {
    margin-bottom: 32px;
    text-align: left;
    font-size: 20px;
}

.loading-plan-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.BillingCardContent {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.BillingCardContentTitle {
    font-weight: bold;
    padding-bottom: 16px;
}

.BillingCardContentPrice {
    color: #3574F3;
    padding-top: 16px;
    padding-bottom: 16px;
}

.resubscribeSubscription {
    padding: 20px;
    padding-top: 40px;
}

.resubscribeSubscription a {
    color: #3574F3;
}

.cancelSubscription {
    padding: 20px;
    padding-top: 40px;
}

.cancelSubscription a {
    color: #aaa;
}
.StripeElement {
    width: 100%;
    padding: 10px;
    border: 1px solid #dde4ed;
    border-radius: 5px;
}

.stripe-checkout-form label {
    width: 100%;
}

.billing-settings-existing-cards {
    padding: 10px;
    border: 2px solid #dde4ed;
    border-radius: 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.billing-settings-existing-cards-logo {
    width: 40px;
    margin-right: 16px;
}

.billing-settings-existing-cards-logo img {
    width: 100%;
    height: auto;
}

.billing-settings-existing-cards-info {
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

.billing-settings-existing-cards-edit {
    cursor: pointer;
    padding: 10px 14px;
    border-radius: 5px;
    background-color: #bcc4cc;
}

.billing-settings-existing-cards-edit path {
    color: #fff;
}

.billing-settings-existing-cards-edit:hover {
    background-color: #3574F3;
}
.project-ranking-list {
  width: 100%;
}

.BattlePage {
  overflow: auto;
  height: 100vh;
}

.GifContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.battle-category-selection {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.battle-category-selection .btn-group {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 5px;
}

.battle-category-selection .btn-group .btn {
  border: none !important;
}
.user-ranking-item {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    border: 2px solid #dde4ed;
    box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.04);
    padding: 10px;
    margin: 10px;
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 0px;
    border-radius: 8px;
    background-color: #fff;
    position: relative;
    text-align: left;
    font-weight: bold;
}

.user-ranking-item img {
    height: 30px;
    border-radius: 15px;
    margin-right: 8px;
}

.user-ranking-item-img-loading {
    display: block;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    margin-right: 8px;
    background-color: #ddd;
}

.user-ranking-item-currnet-points {
    display: block;
    border-radius: 5px;
    color: #3574F3;
    text-align: center;
    line-height: 30px;
}

.user-ranking-item span:first-of-type {
    display: block;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    text-align: left;
}

.weekly-winner {
    margin-right: 10px;
    margin-left: 5px;
}
.giphy-query {
	display: -webkit-flex;
	display: flex;
}

.giphy-form-input {
	-webkit-flex-grow: 2;
	        flex-grow: 2;
	display: block;
}

.giphy-form-submit {
	-webkit-flex-grow: 0;
	        flex-grow: 0;
    display: block;
    margin-left: 5px;
}

.edit-gif-button {
	padding-top: 6px;
}

.user-settings-container .form-control {
    margin-bottom: 8px;
}
.verify-email-header {
    font-size: 40px;
    display: block;
}

.confetti-center {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.verify-email-body {
    margin-bottom: 20px;
}

.modal-center-content {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.kanban-column {
    border: 0px solid #dde4ed;
    background-color: #fff;
    width: 280px;
    max-height: calc(100vh - 64px);
    border-radius: 5px;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
    margin: 32px;
    margin-right: -16px;
}

.kanban-column-header {
    height: 44px;
    font-weight: bold;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
    line-height: 44px;
}

.kanban-column-header-stats {
    font-weight: normal;
}

.kanban-column-header-stats-label {
    font-size: 14px;
    padding-left: 12px;
    padding-right: 2px;
}

.kanban-column-header-stats svg {
    font-size: 14px;
    margin-left: 4px;
    padding-bottom: 2px;
}

.kanban-column-body {
    height: calc(100vh - 107px);
    overflow-y: auto;
    overflow-x: hidden;
}
.kanban-column-body-drag {
    padding: 0px;
    padding-bottom: 0px;
    height: calc(100vh - 107px);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.kanban-container-scroll {
    width: 100%;
    height: 100vh;
    overflow-x: auto;
}

.kanban-container-scroll-container {
    display: -webkit-flex;
    display: flex;
    height: 100vh;
    background-color: #F5F7FA;
}

.bug-modal-close-btn-full {
    position: absolute;
    top: 32px;
    right: 32px;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: #33375A;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
}

.bug-modal-close-btn-full:hover {
    background-color: #000;
}

.bug-modal-close-btn-full svg path {
    color: #fff;
}

.bug-modal-close-btn-modally {
    position: absolute;
    top: 26px;
    right: 4px;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: #33375A;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
}

.bug-modal-close-btn-modally:hover {
    background-color: #000;
}

.bug-modal-close-btn-modally svg path {
    color: #fff;
}

.bug-modal-close-btn {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: #33375A;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
}

.bug-modal-close-btn:hover {
    background-color: #000;
}

.bug-modal-close-btn svg path {
    color: #fff;
}

.gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
}

.gu-hide {
    display: none !important;
}

.gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}

.gu-transit {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
}

.BoardColumn {
    width: 100%;
    height: calc(100vh - 107px);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}
.bugcard-container {
    border: 1px solid #dde4ed;
    background-color: #fff;
    width: calc(100% - 32px);
    border-radius: 5px;
    margin: 16px;
    margin-top: 8px;
    margin-bottom: 0px;
    padding: 10px;
    position: relative;
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
}

.bugcard-container-placeholder {
    width: calc(100% - 16px);
    padding: 10px;
    height: 88px;
}

.bugStorypointContainerFlex {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}
.mainsidebar--container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    height: 100vh;
    background-color: #fff;
    border-right: 2px solid #EBECF1;
}

.mainsidebar--logo {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
    padding: 14px;
    width: 65px;
}

.mainsidebar--logo:hover {
    background-color: #e1e6eb3d;
}

.mainsidebar--logo img {
    width: 100%;
    height: auto;
    height: auto;
    object-fit: contain;
}

.mainsidebar--items {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    padding-bottom: 13px;
    padding-top: 0px;
    margin-top: 41px;
}

.mainsidebar--project-item {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  cursor: pointer;
  margin-top: 8px;
  text-transform: uppercase;
  background-color: #fff;
  border: 2px solid #dde4ed;
  color: #33375a;
  font-weight: bold;
  font-size: 14px;
  line-height: 42px;
  text-align: center;
}

.mainsidebar--project-item:hover {
  border: 2px solid #3574f3;
  color: #3574f3;
}

.mainsidebar--item {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 0px;
    cursor: pointer;
    margin-top: 0px;
    font-size: 22px;
    width: 63px;
    height: 55px;
}

.mainsidebar--item svg path {
    color: #33375A;
}

.mainsidebar--item:hover svg path {
  color: #135DEE;
}

.mainsidebar--item--active {
  background-color: #F5F7FA;
  border-left: 3px solid #3574F3;
}

.mainsidebar--item--active svg path {
  color: #3574F3;
}

.mainsidebar--spacer {
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

.mainsidebar--userinfo {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 65px;
    cursor: pointer;
    margin-top: 8px;
    font-size: 20px;
    position: relative;
    padding-bottom: 24px;
    padding-top: 24px;
}

.mainsidebar--userinfo:hover {
  background-color: #e1e6eb3d;
}

.mainsidebar--userinfo-trophy {
    text-align: center;
}

.mainsidebar--userinfo-trophy-points {
    font-size: 14px;
    font-weight: bold;
    color: #33375A;
    display: block;
    text-align: center;
}

.mainsidebar--userinfo-trophy-icon {
    display: block;
    text-align: center;
    font-size: 18px;
}

.mainsidebar--userinfo-trophy span svg path {
    color: #33375A;
}

.mainsidebar--userinfo img {
    width: 36px;
    height: auto;
    object-fit: contain;
    border-radius: 50%;
}

/** TOOLTIPS **/
.rc-tooltip.rc-tooltip-zoom-enter,
.rc-tooltip.rc-tooltip-zoom-leave {
  display: block;
}
.rc-tooltip-zoom-enter,
.rc-tooltip-zoom-appear {
  opacity: 0;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
          animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-tooltip-zoom-leave {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
          animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active,
.rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
  -webkit-animation-name: rcToolTipZoomIn;
          animation-name: rcToolTipZoomIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
  -webkit-animation-name: rcToolTipZoomOut;
          animation-name: rcToolTipZoomOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
@-webkit-keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@-webkit-keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
}
@keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
}
.rc-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  font-size: 12px;
  line-height: 1.5;
  opacity: 1;
}
.rc-tooltip-hidden {
  display: none;
}
.rc-tooltip-placement-top,
.rc-tooltip-placement-topLeft,
.rc-tooltip-placement-topRight {
  padding: 5px 0 9px 0;
}
.rc-tooltip-placement-right,
.rc-tooltip-placement-rightTop,
.rc-tooltip-placement-rightBottom {
  padding: 0 5px 0 9px;
}
.rc-tooltip-placement-bottom,
.rc-tooltip-placement-bottomLeft,
.rc-tooltip-placement-bottomRight {
  padding: 9px 0 5px 0;
}
.rc-tooltip-placement-left,
.rc-tooltip-placement-leftTop,
.rc-tooltip-placement-leftBottom {
  padding: 0 9px 0 5px;
}
.rc-tooltip-inner {
  padding: 8px 10px;
  color: #617084;
  text-align: left;
  text-decoration: none;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
  min-height: 34px;
}
.rc-tooltip-inner span {
    color: #33375A;
    font-weight: bold;
}
.rc-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  bottom: 4px;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #e1e6eb;
}
.rc-tooltip-placement-top .rc-tooltip-arrow {
  left: 50%;
}
.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
  left: 15%;
}
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  right: 15%;
}
.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  left: 4px;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #e1e6eb;
}
.rc-tooltip-placement-right .rc-tooltip-arrow {
  top: 50%;
}
.rc-tooltip-placement-rightTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  bottom: 15%;
}
.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  right: 4px;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #e1e6eb;
}
.rc-tooltip-placement-left .rc-tooltip-arrow {
  top: 50%;
}
.rc-tooltip-placement-leftTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  bottom: 15%;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  top: 4px;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #e1e6eb;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  left: 50%;
}
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
  left: 15%;
}
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  right: 15%;
}
.ExternalContainer {
    width: 100%;
    height: 100vh;
    margin: auto;
}

.ExternalAppHeader {
    background-color: #3574F3;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-bottom: 0px;
    padding-bottom: 8px;
}

.ExternalAppHeader .title {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    line-height: 42px;
}

.ExternalAppHeader .actions {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.ExternalAppHeader .actions .btn-group {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 5px;
}

.ExternalAppHeader .actions .btn-group .btn {
    border: none !important;
}

.externalBugsContainer {
    height: calc(100vh - 84px);
    overflow-y: auto;
    padding-top: 16px;
    max-width: 550px;
    margin: auto;
}
.comments {
    overflow: auto;
}

.comments-no-comments {
    text-align: center;
    padding: 16px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.center-confettig-for-tester {
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.tester-answer-buttons {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-top: 8px;
}

.tester-answer-buttons .btn {
    margin-top: 0px;
}

.tester-answer-buttons .btn:first-of-type {
    margin-right: 4px;
}

.tester-answer-buttons .btn:last-of-type {
    margin-left: 4px;
}

@media only screen and (max-width: 600px) {
    .bugdetail-bug-meta-card {
        width: 100%;
    }
}
.mainsidebar--container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    height: 100vh;
    background-color: #fff;
    border-right: 2px solid #EBECF1;
}

.mainsidebar--logo {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
    padding: 14px;
    width: 65px;
}

.mainsidebar--logo:hover {
    background-color: #e1e6eb3d;
}

.mainsidebar--logo img {
    width: 100%;
    height: auto;
    height: auto;
    object-fit: contain;
}

.mainsidebar--items {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    padding-bottom: 13px;
    padding-top: 0px;
    margin-top: 41px;
}

.mainsidebar--project-item {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  cursor: pointer;
  margin-top: 8px;
  text-transform: uppercase;
  background-color: #fff;
  border: 2px solid #dde4ed;
  color: #33375a;
  font-weight: bold;
  font-size: 14px;
  line-height: 42px;
  text-align: center;
}

.mainsidebar--project-item:hover {
  border: 2px solid #3574f3;
  color: #3574f3;
}

.mainsidebar--item {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 0px;
    cursor: pointer;
    margin-top: 0px;
    font-size: 22px;
    width: 63px;
    height: 55px;
}

.mainsidebar--item svg path {
    color: #33375A;
}

.mainsidebar--item:hover svg path {
  color: #135DEE;
}

.mainsidebar--item--active {
  background-color: #F5F7FA;
  border-left: 3px solid #3574F3;
}

.mainsidebar--item--active svg path {
  color: #3574F3;
}

.mainsidebar--spacer {
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

.mainsidebar--userinfo {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 65px;
    cursor: pointer;
    margin-top: 8px;
    font-size: 20px;
    position: relative;
    padding-bottom: 24px;
    padding-top: 24px;
}

.mainsidebar--userinfo:hover {
  background-color: #e1e6eb3d;
}

.mainsidebar--userinfo-trophy {
    text-align: center;
}

.mainsidebar--userinfo-trophy-points {
    font-size: 14px;
    font-weight: bold;
    color: #33375A;
    display: block;
    text-align: center;
}

.mainsidebar--userinfo-trophy-icon {
    display: block;
    text-align: center;
    font-size: 18px;
}

.mainsidebar--userinfo-trophy span svg path {
    color: #33375A;
}

.mainsidebar--userinfo img {
    width: 36px;
    height: auto;
    object-fit: contain;
    border-radius: 50%;
}

/** TOOLTIPS **/
.rc-tooltip.rc-tooltip-zoom-enter,
.rc-tooltip.rc-tooltip-zoom-leave {
  display: block;
}
.rc-tooltip-zoom-enter,
.rc-tooltip-zoom-appear {
  opacity: 0;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
          animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-tooltip-zoom-leave {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
          animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active,
.rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
  -webkit-animation-name: rcToolTipZoomIn;
          animation-name: rcToolTipZoomIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
  -webkit-animation-name: rcToolTipZoomOut;
          animation-name: rcToolTipZoomOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
@-webkit-keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@-webkit-keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
}
@keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
}
.rc-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  font-size: 12px;
  line-height: 1.5;
  opacity: 1;
}
.rc-tooltip-hidden {
  display: none;
}
.rc-tooltip-placement-top,
.rc-tooltip-placement-topLeft,
.rc-tooltip-placement-topRight {
  padding: 5px 0 9px 0;
}
.rc-tooltip-placement-right,
.rc-tooltip-placement-rightTop,
.rc-tooltip-placement-rightBottom {
  padding: 0 5px 0 9px;
}
.rc-tooltip-placement-bottom,
.rc-tooltip-placement-bottomLeft,
.rc-tooltip-placement-bottomRight {
  padding: 9px 0 5px 0;
}
.rc-tooltip-placement-left,
.rc-tooltip-placement-leftTop,
.rc-tooltip-placement-leftBottom {
  padding: 0 9px 0 5px;
}
.rc-tooltip-inner {
  padding: 8px 10px;
  color: #617084;
  text-align: left;
  text-decoration: none;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
  min-height: 34px;
}
.rc-tooltip-inner span {
    color: #33375A;
    font-weight: bold;
}
.rc-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  bottom: 4px;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #e1e6eb;
}
.rc-tooltip-placement-top .rc-tooltip-arrow {
  left: 50%;
}
.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
  left: 15%;
}
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  right: 15%;
}
.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  left: 4px;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #e1e6eb;
}
.rc-tooltip-placement-right .rc-tooltip-arrow {
  top: 50%;
}
.rc-tooltip-placement-rightTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  bottom: 15%;
}
.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  right: 4px;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #e1e6eb;
}
.rc-tooltip-placement-left .rc-tooltip-arrow {
  top: 50%;
}
.rc-tooltip-placement-leftTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  bottom: 15%;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  top: 4px;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #e1e6eb;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  left: 50%;
}
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
  left: 15%;
}
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  right: 15%;
}
