.ExternalContainer {
    width: 100%;
    height: 100vh;
    margin: auto;
}

.ExternalAppHeader {
    background-color: #3574F3;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 0px;
    padding-bottom: 8px;
}

.ExternalAppHeader .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    line-height: 42px;
}

.ExternalAppHeader .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.ExternalAppHeader .actions .btn-group {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 5px;
}

.ExternalAppHeader .actions .btn-group .btn {
    border: none !important;
}

.externalBugsContainer {
    height: calc(100vh - 84px);
    overflow-y: auto;
    padding-top: 16px;
    max-width: 550px;
    margin: auto;
}