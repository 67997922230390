.project-ranking-list {
  width: 100%;
}

.BattlePage {
  overflow: auto;
  height: 100vh;
}

.GifContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.battle-category-selection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.battle-category-selection .btn-group {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 5px;
}

.battle-category-selection .btn-group .btn {
  border: none !important;
}