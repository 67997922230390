.user-item-small {
    border: 0px solid #dde4ed;
    padding: 0px;
    margin: 0px;
    border-radius: 8px;
    background-color: transparent;
    position: relative;
    cursor: pointer;
    text-align: left;
    font-weight: bold;
}

.user-item-small img {
    height: 16px;
    border-radius: 8px;
}

.user-item-small-img-loading {
    display: inline-block;
    height: 16px;
    width: 16px;
    border-radius: 8px;
    background-color: #ddd;
}