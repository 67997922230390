.user-item {
    border: 2px solid #dde4ed;
    box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.04);
    padding: 8px;
    margin: 10px;
    margin-right: 16px;
    margin-left: 0px;
    border-radius: 8px;
    background-color: #fff;
    position: relative;
    cursor: pointer;
    text-align: left;
    font-weight: bold;
    padding-right: 16px;
    display: flex;
    flex-direction: row;
}

.user-item img {
    height: 30px;
    border-radius: 15px;
    margin-right: 8px;
}

.user-item-img-loading {
    display: inline-block;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    margin-right: 8px;
    background-color: #ddd;
}

.user-item-cont {
    display: flex;
    flex-direction: column;
}

.user-item-sub {
    font-weight: 500;
    font-size: 11px;
    line-height: 12px;
    display: block;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
}

.user-item-main {
    font-weight: bold;
    display: block;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
    line-height: 17px;
}