.user-item-list {
    padding: 3px;
    margin: 0px;
    margin-right: 0px;
    margin-left: 0px;
    position: relative;
    cursor: pointer;
    text-align: left;
    display: flex;
    line-height: 30px;
}

.user-item-list img {
    height: 30px;
    border-radius: 15px;
    margin-right: 11px;
}

.user-item-list-img-loading {
    display: inline-block;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    margin-right: 8px;
    background-color: #fff !important;
    border: 1px solid #dde4ed;
}