.StripeElement {
    width: 100%;
    padding: 10px;
    border: 1px solid #dde4ed;
    border-radius: 5px;
}

.stripe-checkout-form label {
    width: 100%;
}

.billing-settings-existing-cards {
    padding: 10px;
    border: 2px solid #dde4ed;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.billing-settings-existing-cards-logo {
    width: 40px;
    margin-right: 16px;
}

.billing-settings-existing-cards-logo img {
    width: 100%;
    height: auto;
}

.billing-settings-existing-cards-info {
    flex-grow: 1;
}

.billing-settings-existing-cards-edit {
    cursor: pointer;
    padding: 10px 14px;
    border-radius: 5px;
    background-color: #bcc4cc;
}

.billing-settings-existing-cards-edit path {
    color: #fff;
}

.billing-settings-existing-cards-edit:hover {
    background-color: #3574F3;
}