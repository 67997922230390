.projects-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.projects-list-container {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    cursor: pointer;
    border-radius: 5px;
}

.projectheader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;
}

.projects-list-description {
    margin-bottom: 32px;
    margin-top: -16px;
}