.buglist-filter-dropdown {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.buglist-filter-dropdown .dropdown {
    width: calc(50% - 3px);
}

.buglist-filter-dropdown .dropdown:first-of-type {
    margin-right: 6px;
}

.buglist-filter-dropdown .dropdown .dropdown-toggle {
    border-radius: 5px;
    padding: 0px;
    width: 100%;
    margin-bottom: 2.5px;
    background-color: #fff;
}

.buglist-filter-dropdown .dropdown .dropdown-toggle::after {
    position: absolute;
    right: 8px;
    top: calc(50% - 2px);
}

.buglist-filter-dropdown .dropdown .dropdown-menu {
    width: 280px;
}

.buglist-filter-dropdown .dropdown-toggle .dropdown-item {
    padding: 0px;
}

.buglist-filter-dropdown .dropdown-toggle .user-item-list {
    padding: 2px;
    padding-left: 10px;
    padding-right: 30px;
    line-height: 21px;
}

.buglist-filter-dropdown .dropdown-toggle .user-item-list-img-loading {
    width: 17px;
    height: 17px;
    margin-top: 2px;
}

.buglist-filter-dropdown .dropdown-toggle img {
    width: 17px;
    height: 17px;
    margin-top: 2px;
}

.buglist-filter-dropdown .dropdown-toggle .bugAssignedToItem {
    padding: 0px;
}

.bugListFilterItem {
    width: 100%;
    padding: 2px;
    padding-left: 20px;
    padding-right: 30px;
    position: relative;
    text-align: left;
}