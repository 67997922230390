.comments {
    overflow: auto;
}

.comments-no-comments {
    text-align: center;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}