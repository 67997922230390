.center-confettig-for-tester {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tester-answer-buttons {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
}

.tester-answer-buttons .btn {
    margin-top: 0px;
}

.tester-answer-buttons .btn:first-of-type {
    margin-right: 4px;
}

.tester-answer-buttons .btn:last-of-type {
    margin-left: 4px;
}

@media only screen and (max-width: 600px) {
    .bugdetail-bug-meta-card {
        width: 100%;
    }
}