.project-plans {
    text-align: center;
}

.project-plans td {
    width: 33.3333 !important;
}

.current-plan {
    line-height: 34px;
    font-weight: bold;
    color: #3574F3;
    border: #3574F3 solid 1px;
    border-radius: 5px;
    padding: 0px 20px;
    width: 100%;
}

.next-payment {
    margin-top: 8px;
    font-style: italic;
}

.loading-plan {
    margin-bottom: 32px;
    text-align: left;
    font-size: 20px;
}

.loading-plan-container {
    display: flex;
    justify-content: left;
    align-items: flex-start;
    flex-direction: column;
}

.BillingCardContent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.BillingCardContentTitle {
    font-weight: bold;
    padding-bottom: 16px;
}

.BillingCardContentPrice {
    color: #3574F3;
    padding-top: 16px;
    padding-bottom: 16px;
}

.resubscribeSubscription {
    padding: 20px;
    padding-top: 40px;
}

.resubscribeSubscription a {
    color: #3574F3;
}

.cancelSubscription {
    padding: 20px;
    padding-top: 40px;
}

.cancelSubscription a {
    color: #aaa;
}