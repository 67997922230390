.steps-to-reproduce {
    display: flex;
    flex-direction: row;
    margin-bottom: 6px;
}

.steps-to-reproduce-icon-container-inner {
    background-color: #F4F7FC;
    padding: 8px;
    margin-right: 8px;
    margin-top: 8px;
    border-radius: 5px;
}

.steps-to-reproduce-icon {
    width: 17px;
    height: 15px;
    font-size: 9px;
    line-height: 15px;
    background-color: #617084;
    border-radius: 3px;
    text-align: center;
}

.steps-to-reproduce-icon svg path {
    color: #fff;
}

.steps-to-reproduce-header {
    display: flex;
    flex-direction: column;
    padding-top: 7px;
    min-width: 90px;
}

.steps-to-reproduce-header-title {
    font-weight: bold;
    line-height: 17px;
    color: #33375A;
}

.steps-to-reproduce-header-date {
    line-height: 17px;
    font-size: 12px;
}

.steps-to-reproduce-header-date time {
    line-height: 17px;
    font-size: 12px;
}

.steps-to-reproduce-body {
    word-wrap: break-word;
    padding-top: 5px;
    padding-right: 10px;
}
