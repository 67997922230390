* {
    font-size: 14px;
    font-family: proxima-nova, sans-serif;
    color: #33375A;
}

.btn-primary path {
  color: #fff;
}

.soft-green-bg {
  background-color: rgba(29, 191, 115, 0.05);
}

.soft-yellow-bg {
  background-color: rgba(236, 178, 45, 0.05);
}

.btn-primary {
    color: #fff;
    background-color: #3574F3;
    border-color: #3574F3;
    border: 0px;
    border-left: 1px solid #ced4da;
    font-weight: bold;
}

.rc-steps-item-finish .rc-steps-item-title:after {
  background-color: #3574F3 !important;
}

.btn-link {
  color: #3574F3;
}

.btn-link:hover {
  color: #135DEE;
}

a:hover {
  color: #135DEE;
}

.btn-primary:hover {
  background-color: #135DEE;
  border-color: #135DEE;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  background-color: #135DEE;
  border-color: #135DEE;
}

.shadow-card {
}

.box-content {
  max-width: 460px;
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #9caabd;
    background-color: #dde4ed;
    border: 0px;
    border-left: 1px solid #ced4da;
}

.nav-item {
    cursor: pointer;
}

a {
    color: #3574F3;
}

.rc-steps-item-finish .rc-steps-item-icon {
    border-color: #3574F3 !important;
}

.rc-steps-item-process .rc-steps-item-icon {
    background-color: #3574F3 !important;
    border-color: #3574F3 !important;
}

.row {
    margin-right: 0px;
    margin-left: 0px;
}

h1 {
    font-size: 26px;
    line-height: 43px;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #33375A;
    margin: 0px;
    padding: 0px;
}

.rc-steps-item-process .rc-steps-item-title {
    color: #33375A;
}

.boldtext {
    font-weight: bold;
    color: #33375A;
}

.subtitle-text {
    text-align: left;
    margin-bottom: 35px;
    margin-top: -10px;
}

.app {
    display: flex;
    flex-direction: row;
}

.planUpgradeInfo {
  text-align: center;
  font-weight: bold;
}

.mainsidebar {
    width: 65px;
    color: #3574F3;
    background-color: #fff;
    height: 100vh;
}

.mainapp {
  width: calc(100% - 66px);
  height: 100vh;
  background-color: #F4F7FC;
}

.hide-sidebar .mainsidebar {
  display: none !important;
}

.hide-sidebar .mainapp {
  width: 100% !important;
}

.nav-tabs {
  background-color: #F4F7FC;
  border-bottom: 0px;
}

.nav-link {
  padding: 16px 24px !important;
  border: 0px !important;
  border-top: 2px solid transparent !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.nav-link.active {
  border-top: 2px solid #3574F3 !important;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-top: 2px solid #3574F3 !important;
  background-color: rgba(255, 255, 255, 0.4) !important;
}

.mainapp-full-width {
    width: 100%;
    overflow: inherit;
}

.form-control {
    border-width: 2px;
    border-color: #dde4ed;
    padding: 20px;
    padding-left: 16px;
    padding-right: 16px;
}

.dropdown .dropdown-toggle {
    border: 0px solid #dde4ed;
    background-color: #F4F7FC;
}

.dropdown-menu {
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  padding: 10px 0;
  background-color: #fff;
  box-shadow: 0 4px 14px 0 rgba(0,0,0,.2),0 0 0 1px rgba(0,0,0,.05);
}

.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #617084;
}

.dropdown-item.active span, .dropdown-item:active span {
    color: #fff;
    text-decoration: none;
    background-color: #617084;
}

.dropdown-item.active .bugPriorityItem span, .dropdown-item:active .bugPriorityItem span {
    color: #fff;
    text-decoration: none;
}

.dropdown .dropdown-toggle::after {
    color: #617084;
}

.dropdown.show .dropdown-toggle::after {
    color: #fff;
}

.dropdown-item.active .bugAssignedToItem div div, .dropdown-item:active .bugAssignedToItem div div {
    color: #fff;
    text-decoration: none;
}

.dropdown-item.active .bugPriorityItem span path, .dropdown-item:active .bugPriorityItem span path {
    color: #fff;
    text-decoration: none;
}

.dropdown-item.active .bugStatusItem span, .dropdown-item:active .bugStatusItem span {
    color: #fff;
    text-decoration: none;
}

.dropdown-menu {
    padding: 0px;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
    background-color: #F4F7FC;
    border-color: #F4F7FC;
    color: #617084;
}

.btn-secondary:not(:disabled):not(.disabled):active div div, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle div div {
  background-color: #F4F7FC;
  border-color: #F4F7FC;
  color: #617084;
}

.btn-secondary:not(:disabled):not(.disabled):active span, .btn-secondary:not(:disabled):not(.disabled).active span, .show > .btn-secondary.dropdown-toggle span {
    /*color: #fff;*/
}

.btn-secondary:not(:disabled):not(.disabled):active span path, .btn-secondary:not(:disabled):not(.disabled).active span path, .show > .btn-secondary.dropdown-toggle span path {
    /*color: #fff;*/
}

.Container {
    display: flex;
    flex-direction: row;
    background-color: #F5F7FA;
}

.BattlePage > .Container{
  background-color: initial;
}

.Container .FullWidthContainer {
  width: 100%;
  padding: 0px;
  padding-right: 0px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  height: 100vh;
  overflow: auto;
  background-color: #fff;
}

.Container .LeftSidebar {
    width: 280px;
    min-width: 280px;
    padding: 0px;
    padding-right: 0px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    height: calc(100vh);
    overflow: auto;
}

.pt-0 {
  padding-top: 0px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-0 {
  margin-top: 0px !important;
}

.MainContainerFull {
  width: 100%;
  padding: 0px;
  padding-right: 0px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
  border-radius: 0px;
  height: calc(100vh);
  overflow: auto;
  background-color: #fff;
}

.MainContainerFullInner {
  padding: 64px;
  max-width: 900px;
}

.MainContainerFullInnerFullWidth {
  padding: 64px;
}

.Container .LeftSidebarHeader {
  color: #33375A;
  font-size: 22px;
  font-weight: bold;
  padding: 16px;
  padding-bottom: 0px;
}

.LeftNavigationBar {
  background-color: #F5F7FA;
  width: 280px;
  min-width: 280px;
  height: 100vh;
}

.Container .MainContainer {
  flex-grow: 1;
  padding: 0px;
  height: 100vh;
  overflow: auto;
  background-color: #fff;
}

.FullPageContainer {
    width: calc(100% - 64px);
    margin: 32px;
}

.dashboard-dialog-card {
    position: absolute;
    top: 127px;
    left: 50%;
    margin-left: -200px;
    padding: 32px;
    border: 0px solid #dde4ed;
    border-radius: 20px;
    background-color: #fff;
    width: 400px;
    text-align: center;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
    z-index: 99;
}

.dashboard-dialog-card-body {
    margin-bottom: 24px;
    margin-top: 8px;
}

.dashboard-dialog-card-header {
    font-size: 40px;
    display: block;
}

.codeblock {
    word-wrap: break-word;
    word-break: break-word;
    padding: 10px;
    margin: 1em 0;
    line-height: 1.65;
    border: 1px solid #EAEAEA;
    color: #33375A;
    background: #f8f8f8;
    overflow: auto;
    border-radius: 5px;
}

pre {
    word-wrap: break-word;
    word-break: break-word;
    padding: 10px;
    margin: 1em 0;
    line-height: 1.65;
    border: 1px solid #EAEAEA;
    color: #33375A;
    background: #f8f8f8;
    overflow: auto;
    border-radius: 5px;
}

.ml-8 {
  margin-left: 8px;
}

.mt-0 {
  margin-top: 0px !important;
}

.mw-550 {
  min-width: 550px;
}

.mt-16 {
    margin-top: 16px;
}

.mb-16 {
    margin-bottom: 16px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-8 {
    margin-top: 8px;
}

.mt-32 {
    margin-top: 32px;
}

.mb-32 {
  margin-bottom: 32px;
}

.m-32 {
    margin: 32px;
}

.p-32 {
    padding: 32px !important;
}

.mb-8 {
    margin-bottom: 8px;
}

h2.card-title {
    text-align: left;
    font-size: 18px;
    font-weight: normal;
    padding: 0px;
    margin: 0px;
    margin-bottom: 16px;
    line-height: 24px;
}

.CenterMainContainer {
    width: 90%;
    margin: auto;
    max-width: 780px;
}

.LeftContainer {
    width: 66%;
}

.RightContainer {
    width: 33%;
}

.LeftContainerEqual {
    width: 40%;
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 32px;
    padding-right: 8px;
}

.LeftContainerBattle {
  width: 50%;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 5%;
  padding-right: 5%;
}

.GiphyContainer {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100vh;
  width: calc(50% - 32px);
  background-position: center center;
  background-size: cover;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 32px;
  padding-right: 32px;
}

.RightContainerEqual {
    width: 50%;
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 8px;
    padding-right: 32px;
}

.Toastify__toast-container {
    z-index: 9999;
    position: fixed;
    padding: 4px;
    width: 320px;
    box-sizing: border-box;
    color: #fff; }
    .Toastify__toast-container--top-left {
      top: 1em;
      left: 1em; }
    .Toastify__toast-container--top-center {
      top: 1em;
      left: 50%;
      margin-left: -160px; }
    .Toastify__toast-container--top-right {
      top: 1em;
      right: 1em; }
    .Toastify__toast-container--bottom-left {
      bottom: 1em;
      left: 1em; }
    .Toastify__toast-container--bottom-center {
      bottom: 1em;
      left: 50%;
      margin-left: -160px; }
    .Toastify__toast-container--bottom-right {
      bottom: 1em;
      right: 1em; }
  
  @media only screen and (max-width: 480px) {
    .Toastify__toast-container {
      width: 100vw;
      padding: 0;
      left: 0;
      margin: 0; }
      .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
        top: 0; }
      .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
        bottom: 0; }
      .Toastify__toast-container--rtl {
        right: 0;
        left: initial; } }
  
  .Toastify__toast {
    position: relative;
    min-height: 64px;
    box-sizing: border-box;
    margin-bottom: 1rem;
    padding: 8px;
    border-radius: 5px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    max-height: 800px;
    overflow: hidden;
    font-family: sans-serif;
    cursor: pointer;
    direction: ltr; }
    .Toastify__toast--rtl {
      direction: rtl; }
    .Toastify__toast--default {
      background: #fff;
      color: #aaa; }
    .Toastify__toast--info {
      background: #3498db; }
    .Toastify__toast--success {
      background: #3574F3; }
    .Toastify__toast--warning {
      background: #f1c40f; }
    .Toastify__toast--error {
      background: #e74c3c; }
    .Toastify__toast-body {
      margin: auto 0;
      -ms-flex: 1;
          flex: 1; }
          .Toastify__toast-body {
              color: #fff !important;
          }
  
  @media only screen and (max-width: 480px) {
    .Toastify__toast {
      margin-bottom: 0; } }
  
  .Toastify__close-button {
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;
    opacity: 0.7;
    transition: 0.3s ease;
    -ms-flex-item-align: start;
        align-self: flex-start; }
    .Toastify__close-button--default {
      color: #000;
      opacity: 0.3; }
    .Toastify__close-button:hover, .Toastify__close-button:focus {
      opacity: 1; }
  
  @keyframes Toastify__trackProgress {
    0% {
      transform: scaleX(1); }
    100% {
      transform: scaleX(0); } }
  
  .Toastify__progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    z-index: 9999;
    opacity: 0.7;
    background-color: rgba(255, 255, 255, 0.7);
    transform-origin: left; }
    .Toastify__progress-bar--animated {
      animation: Toastify__trackProgress linear 1 forwards; }
    .Toastify__progress-bar--controlled {
      transition: transform .2s; }
    .Toastify__progress-bar--rtl {
      right: 0;
      left: initial;
      transform-origin: right; }
    .Toastify__progress-bar--default {
      background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55); }
  
  @keyframes Toastify__bounceInRight {
    from,
    60%,
    75%,
    90%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    from {
      opacity: 0;
      transform: translate3d(3000px, 0, 0); }
    60% {
      opacity: 1;
      transform: translate3d(-25px, 0, 0); }
    75% {
      transform: translate3d(10px, 0, 0); }
    90% {
      transform: translate3d(-5px, 0, 0); }
    to {
      transform: none; } }
  
  @keyframes Toastify__bounceOutRight {
    20% {
      opacity: 1;
      transform: translate3d(-20px, 0, 0); }
    to {
      opacity: 0;
      transform: translate3d(2000px, 0, 0); } }
  
  @keyframes Toastify__bounceInLeft {
    from,
    60%,
    75%,
    90%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    0% {
      opacity: 0;
      transform: translate3d(-3000px, 0, 0); }
    60% {
      opacity: 1;
      transform: translate3d(25px, 0, 0); }
    75% {
      transform: translate3d(-10px, 0, 0); }
    90% {
      transform: translate3d(5px, 0, 0); }
    to {
      transform: none; } }
  
  @keyframes Toastify__bounceOutLeft {
    20% {
      opacity: 1;
      transform: translate3d(20px, 0, 0); }
    to {
      opacity: 0;
      transform: translate3d(-2000px, 0, 0); } }
  
  @keyframes Toastify__bounceInUp {
    from,
    60%,
    75%,
    90%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    from {
      opacity: 0;
      transform: translate3d(0, 3000px, 0); }
    60% {
      opacity: 1;
      transform: translate3d(0, -20px, 0); }
    75% {
      transform: translate3d(0, 10px, 0); }
    90% {
      transform: translate3d(0, -5px, 0); }
    to {
      transform: translate3d(0, 0, 0); } }
  
  @keyframes Toastify__bounceOutUp {
    20% {
      transform: translate3d(0, -10px, 0); }
    40%,
    45% {
      opacity: 1;
      transform: translate3d(0, 20px, 0); }
    to {
      opacity: 0;
      transform: translate3d(0, -2000px, 0); } }
  
  @keyframes Toastify__bounceInDown {
    from,
    60%,
    75%,
    90%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    0% {
      opacity: 0;
      transform: translate3d(0, -3000px, 0); }
    60% {
      opacity: 1;
      transform: translate3d(0, 25px, 0); }
    75% {
      transform: translate3d(0, -10px, 0); }
    90% {
      transform: translate3d(0, 5px, 0); }
    to {
      transform: none; } }
  
  @keyframes Toastify__bounceOutDown {
    20% {
      transform: translate3d(0, 10px, 0); }
    40%,
    45% {
      opacity: 1;
      transform: translate3d(0, -20px, 0); }
    to {
      opacity: 0;
      transform: translate3d(0, 2000px, 0); } }
  
  .Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
    animation-name: Toastify__bounceInLeft; }
  
  .Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
    animation-name: Toastify__bounceInRight; }
  
  .Toastify__bounce-enter--top-center {
    animation-name: Toastify__bounceInDown; }
  
  .Toastify__bounce-enter--bottom-center {
    animation-name: Toastify__bounceInUp; }
  
  .Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
    animation-name: Toastify__bounceOutLeft; }
  
  .Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
    animation-name: Toastify__bounceOutRight; }
  
  .Toastify__bounce-exit--top-center {
    animation-name: Toastify__bounceOutUp; }
  
  .Toastify__bounce-exit--bottom-center {
    animation-name: Toastify__bounceOutDown; }
  
  @keyframes Toastify__zoomIn {
    from {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3); }
    50% {
      opacity: 1; } }
  
  @keyframes Toastify__zoomOut {
    from {
      opacity: 1; }
    50% {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3); }
    to {
      opacity: 0; } }
  
  .Toastify__zoom-enter {
    animation-name: Toastify__zoomIn; }
  
  .Toastify__zoom-exit {
    animation-name: Toastify__zoomOut; }
  
  @keyframes Toastify__flipIn {
    from {
      transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      animation-timing-function: ease-in;
      opacity: 0; }
    40% {
      transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
      animation-timing-function: ease-in; }
    60% {
      transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
      opacity: 1; }
    80% {
      transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
    to {
      transform: perspective(400px); } }
  
  @keyframes Toastify__flipOut {
    from {
      transform: perspective(400px); }
    30% {
      transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
      opacity: 1; }
    to {
      transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      opacity: 0; } }
  
  .Toastify__flip-enter {
    animation-name: Toastify__flipIn; }
  
  .Toastify__flip-exit {
    animation-name: Toastify__flipOut; }
  
  @keyframes Toastify__slideInRight {
    from {
      transform: translate3d(110%, 0, 0);
      visibility: visible; }
    to {
      transform: translate3d(0, 0, 0); } }
  
  @keyframes Toastify__slideInLeft {
    from {
      transform: translate3d(-110%, 0, 0);
      visibility: visible; }
    to {
      transform: translate3d(0, 0, 0); } }
  
  @keyframes Toastify__slideInUp {
    from {
      transform: translate3d(0, 110%, 0);
      visibility: visible; }
    to {
      transform: translate3d(0, 0, 0); } }
  
  @keyframes Toastify__slideInDown {
    from {
      transform: translate3d(0, -110%, 0);
      visibility: visible; }
    to {
      transform: translate3d(0, 0, 0); } }
  
  @keyframes Toastify__slideOutRight {
    from {
      transform: translate3d(0, 0, 0); }
    to {
      visibility: hidden;
      transform: translate3d(110%, 0, 0); } }
  
  @keyframes Toastify__slideOutLeft {
    from {
      transform: translate3d(0, 0, 0); }
    to {
      visibility: hidden;
      transform: translate3d(-110%, 0, 0); } }
  
  @keyframes Toastify__slideOutDown {
    from {
      transform: translate3d(0, 0, 0); }
    to {
      visibility: hidden;
      transform: translate3d(0, 500px, 0); } }
  
  @keyframes Toastify__slideOutUp {
    from {
      transform: translate3d(0, 0, 0); }
    to {
      visibility: hidden;
      transform: translate3d(0, -500px, 0); } }
  
  .Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
    animation-name: Toastify__slideInLeft; }
  
  .Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
    animation-name: Toastify__slideInRight; }
  
  .Toastify__slide-enter--top-center {
    animation-name: Toastify__slideInDown; }
  
  .Toastify__slide-enter--bottom-center {
    animation-name: Toastify__slideInUp; }
  
  .Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
    animation-name: Toastify__slideOutLeft; }
  
  .Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
    animation-name: Toastify__slideOutRight; }
  
  .Toastify__slide-exit--top-center {
    animation-name: Toastify__slideOutUp; }
  
  .Toastify__slide-exit--bottom-center {
    animation-name: Toastify__slideOutDown; }

.payment-button-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.payment-button-line img {
    height: 24px;
    width: auto;
}

.payment-button-line-loading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.CardBoxBorder {
  padding: 32px;
  border: 0px solid #dde4ed;
  border-radius: 5px;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
  background-color: #fff;
}

.CardBoxBorder--topnav {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.CardBoxBorder--blue {
  color: #fff;
  background-color: #3574F3;
}

.CardBoxBorder--blue h1 {
  color: #fff;
}

.CardBoxBorder--blue .subtitle-text {
  color: #fff;
}

.center {
  text-align: center;
}

.bold {
  font-weight: bold;
}

.width-50 {
  width: 50%;
}

.center-table-cell-title {
  text-align: center !important;
  padding: 0px !important;
  margin: 0px !important;
}

.btn-lg, .btn-group-lg > .btn {
  font-size: 1.1rem;
}

.btn-white {
  background-color: #fff;
  font-weight: bold;
}

.bugdetails-modal-container {
  height: 100vh;
  border-radius: 0px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, .25) !important;
}

.invoice-line {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  border: 2px solid #dde4ed;
  padding: 16px;
  margin-bottom: 8px;
  border-radius: 5px;
}

.center {
  text-align: center;
  width: 100%;
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}

.billingDidExpire {
  background-color: #4C6085;
  color: #fff;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 5px;
}

.billingDidExpire a {
  color: #fff;
  font-weight: bold;
}

.trialExpiredInterstelar {
  background-color: #fff;
  color: #3574F3;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.trialExpiredInterstelarContainer {
  color: #33375A;
  margin-bottom: 16px;
}

.trialExpiredInterstelarContainer a {
  color: #3574F3;
  font-weight: bold;
}

.trialExpiredInterstelarContainer {
  max-width: 350px;
  text-align: center;
}

.externalCard {
  border: 2px solid #dde4ed;
  background-color: #fff;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 16px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
}

.externalCard > div {
  padding: 24px;
}

.externalCard .title {
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
}

.externalCard .type {
  color: rgb(6, 147, 227);
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  line-height: 30px;
}

.externalCard .type--feedback {
  color: rgb(142, 209, 252);
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  line-height: 30px;
}

.loadingOverlayExternal {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: none;
  background-color: #fff;
  z-index: 99999;
  justify-content: center;
  align-items: center;
}

.loadingOverlayExternal--active {
  display: flex;
}

.bugsListLoading {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bugpoints-important {
  color: #ff0000;
}

.bugpoints-medium {
  color: #f7941d;
}

.bugpoints-new {
  
}

.project-quick-selection-container {
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 12;
}

.project-selector-top {
  padding: 20px;
  position: relative;
}

.project-selector-top select {
  border:none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -ms-appearance: none;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
  border: solid 1px #fff;
  color: #33375A;
  line-height: 34px;
  padding: 0px 10px;
  text-align: center;
  min-width: 130px;
  font-weight: bold;
  cursor: pointer;
}

.project-selector-top svg {
  position: absolute;
  top: 31px;
  right: 32px;
}