.settings-tab-item-hero {
    padding: 8px;
    padding-left: 32px;
    font-size: 22px;
    line-height: 22px;
    font-weight: bold;
    color: #33375A;
    padding-top: 44px;
    padding-bottom: 16px;
}

.settings-tab-item {
    padding: 8px;
    padding-left: 32px;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    color: #33375A;
}

.settings-tab-item-spacer {
    width: 200px;
    height: 1px;
    background-color: #bcc4cc;
    margin-bottom: 35px;
    margin-top: 35px;
    margin-left: 24px;
    opacity: 0.3;
}

.settings-tab-item-icon path {
    color: #33375A;
}

.settings-tab-item-icon {
    width: 30px;
}

.settings-tab-item:hover {
    color: #135DEE;
}

.settings-tab-item:hover path {
    color: #135DEE;
}

.settings-tab-item-active {
    color: #3574F3;
}

.settings-tab-item-active path {
    color: #3574F3;
}

.ProjectPageSettings {
    padding-top: 70px;
}

.MainContainerFullHero {
    background-color: #F5F7FA;
    border-radius: 0px;
    padding: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
}

.MainContainerFullHeroTitle {
    font-size: 32px;
    line-height: 50px;
    color: #33375A;
    font-weight: bold;
    padding-right: 35px;
    margin-right: 35px;
    border-right: 1px solid rgba(0,0,0,.1);
}

.MainContainerFullHeroIcon {
    font-size: 30px;
    margin-right: 15px;
    height: 50px;
    display: flex;
    align-items: center;
}

.MainContainerFullHeroIcon path {
    color: #3574F3;
}

.MainContainerFullHeroBody {
    display: flex;
    flex-direction: column;
    height: 50px;
    align-items: flex-start;
    justify-content: space-evenly;
}