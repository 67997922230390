.bugdetail-actions-dropdown-assignedto {

}

.bugdetail-actions-dropdown-assignedto .dropdown .dropdown-toggle {
    border-radius: 5px;
    padding: 0px;
    width: 100%;
}

.bugdetail-actions-dropdown-assignedto .dropdown .dropdown-toggle::after {
    position: absolute;
    right: 16px;
    top: calc(50% - 2px);
}

.bugdetail-actions-dropdown-assignedto .dropdown .dropdown-menu {
    width: 100%;
}

.bugdetail-actions-dropdown-assignedto .dropdown-item {
    padding: 0px;
}

.bugAssignedToItem {
    width: 100%;
    padding: 5px;
    position: relative;
    text-align: left;
}