.user-ranking-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #dde4ed;
    box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.04);
    padding: 10px;
    margin: 10px;
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 0px;
    border-radius: 8px;
    background-color: #fff;
    position: relative;
    text-align: left;
    font-weight: bold;
}

.user-ranking-item img {
    height: 30px;
    border-radius: 15px;
    margin-right: 8px;
}

.user-ranking-item-img-loading {
    display: block;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    margin-right: 8px;
    background-color: #ddd;
}

.user-ranking-item-currnet-points {
    display: block;
    border-radius: 5px;
    color: #3574F3;
    text-align: center;
    line-height: 30px;
}

.user-ranking-item span:first-of-type {
    display: block;
    flex-grow: 1;
    text-align: left;
}

.weekly-winner {
    margin-right: 10px;
    margin-left: 5px;
}