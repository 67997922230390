.ConfigureIntegrationModal {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 50px;
  min-width: 700px;
  z-index: 5;
}

.field-container {
  display: block;
  margin-bottom: 50px;
}

.modal-buttons {
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  bottom: 0;
  /* justify-content: flex-end; */
}

.modal-buttons button {
  margin-left: 10px;
  min-width: 80px;
}
