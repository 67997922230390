.MainContainerFullInner {
  max-width: 100% !important;
}

.stateColorPickerPreview {
  width: 42px;
  height: 42px;
  position: relative;
  cursor: pointer;
}

.stateColorPickerPreviewPicker {
  position: absolute;
  top: 40px;
  left: 0px;
  z-index: 9999;
}

.stateColorPickerPreviewColor {
  width: 100%;
  height: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.state-item-bubble {
  border: 2px solid #dde4ed;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.04);
  padding: 8px;
  margin: 12px;
  margin-right: 16px;
  margin-left: 0px;
  margin-top: 0px;
  border-radius: 5px;
  background-color: #fff;
  position: relative;
  cursor: pointer;
  text-align: left;
  font-weight: bold;
  padding-right: 16px;
  padding-left: 16px;
}

.bugStatusBubbleState {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 12px;
  height: 12px;
  border-radius: 10px;
  z-index: 999;
}

.buttons {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  margin-top: 8px;
}

.modal-buttons {
  display: flex;
  flex-direction: row-reverse;
}

.integrations-container {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-right: 0;
  flex-wrap: wrap;
}

.buttons h2 {
  margin-bottom: 15px;
}

.buttons p {
  margin-bottom: 8px;
}

.integration-card {
  position: relative;
  padding: 25px;
  padding-top: 30px;
  margin-bottom: 40px;
  flex-basis: 400px;
  margin-right: 50px;
  box-shadow: 5px 3px 10px #cacaca;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ribbon {
  width: 120px;
  height: 120px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  /* z-index: -1; */
  content: '';
  display: block;
  border: 5px solid #23912c;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 170px;
  padding: 9px 0;
  background-color: #2cad37;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 600 13px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

.ribbon-top-right {
  top: -10px;
  right: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -15px;
  top: 35px;
  transform: rotate(45deg);
}

.configErrorText {
  margin-bottom: 0;
  position: absolute;
  bottom: 10px;
  color: red;
  font-size: 0.9em;
  text-align: center;
  align-self: center;
}
