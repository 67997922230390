.giphy-query {
	display: flex;
}

.giphy-form-input {
	flex-grow: 2;
	display: block;
}

.giphy-form-submit {
	flex-grow: 0;
    display: block;
    margin-left: 5px;
}

.edit-gif-button {
	padding-top: 6px;
}