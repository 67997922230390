.bugdetail {
    background-color: #fff;
    width: 100%;
    height: calc(100vh - 64px);
    padding: 16px;
    display: flex;
    flex-direction: row;
}

.bugdetail-nobugselected {
    text-align: center;
    width: 100%;
    min-height: calc(100vh - 34px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.bugdetailtabs-container {
    margin-top: 16px;
}

.bugdetail-main {
    flex-grow: 1;
    padding: 0px;
    padding-right: 32px;
    overflow: auto;
}

.bugdetail-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.bugdetail-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.bugdetail-reportedBy {
    display: flex;
    flex-direction: row;
    line-height: 32px;
    font-size: 14px;
    color: #3574FA;
}

.bugdetaill-createdAt time {
    line-height: 32px;
    font-size: 14px;
}

.bugdetail-reportedBy-profile {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: #F4F7FC;
    border: 1px solid #3574FA;
    color: #3574FA;
    margin-right: 8px;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    font-size: 12px;
    line-height: 32px;
}

.bugdetail-main-data {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 24px;
}

.bugdetail-screenshot-container-none {
    background-color: #F4F7FC;
    border-radius: 5px;
}

.bugdetail-screenshot-container {
    width: 10vw;
    min-width: 10vw;
    height: auto;
    margin-right: 16px;
}

.bugdetail-screenshot {
    width: 100%;
    height: auto;
    border: 1px solid #dde4ed;
    background-color: #eee;
    border-radius: 5px;
    cursor: pointer;
}

.bugdetail-description {
    flex-grow: 1;
}

.bugdetail-right-sidebar {
    width: 280px;
    min-width: 280px;
    margin: 6px;
    padding-bottom: 0px;
}

.bugdetail-actions {
    width: 280px;
    min-width: 280px;
    margin-bottom: 16px;
    padding-bottom: 0px;
}

.bugdetail-actions-inner {
    padding: 16px;
    border: 1px solid #dde4ed;
    border-radius: 5px;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
}

.bugdetail-actions-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.bugdetail-actions-header b {
    color: #33375A;
}

.bugdetail-actions-header-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.bugdetail-actions-header-actions .bugdetail-actions-header-action {
    cursor: pointer;
    padding: 4px;
    width: 30px;
    height: 30px;
    text-align: center;
}

.bugdetail-actions-header-actions svg path {
    color: #bcc4cc
}

.bugdetail-actions-header-actions:hover svg path {
    color: #3574F3
}

.bugdetail-bug-title {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 16px;
    color: #33375A;
}

.bugdetail-bug-meta-header-external {
    font-size: 15px;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 8px;
    color: #33375A;
}

.bugdetail-bug-meta-header {
    font-size: 15px;
    font-weight: bold;
    margin-top: 24px;
    margin-bottom: 16px;
    color: #33375A;
}

.bugdetail-bug-meta {
    display: flex;
    flex-wrap: wrap;
}

.bugdetail-bug-meta-card {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    margin-bottom: 16px;
}

.bugdetail-bug-meta-card-icon {
    border: 1px solid #dde4ed;
    border-radius: 5px;
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bugdetail-bug-meta-card-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 28px;
}

.bugdetail-bug-meta-card-title {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 12px;
    padding-left: 12px;
}

.bugdetail-bug-meta-card-data {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    padding-left: 12px;
    padding-right: 12px;
    word-break: break-all;
}

.callstack-info-container {
    word-break: break-all;
}

.callstack-info-container-title {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 16px;
}