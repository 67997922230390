.projectcard {
    padding: 24px;
    border: 2px solid #dde4ed;
    background-color: #fff;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    width: calc(33% - 32px);
    margin-right: 16px;
    margin-bottom: 16px;
    height: 200px;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
}

.projectcard:hover {
    border: 2px solid #3574F3;
}

.projectcard .title {
    font-size: 18px;
    font-weight: bold;
    line-height: 18px;
}

.projectcard .titleid {
    flex-grow: 1;
    flex-grow: 1;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.projectcard .titleid div {
    font-size: 14px;
    flex-grow: 1;
    line-height: 30px;
    color: #999;
    font-size: 14px;
}

.projectcard:hover .title {
    color: #3574F3;
}

.trialInfo {
    display: flex;
    flex-direction: column;
}

.trialInfo span:first-child {
    display: block;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    text-align: left;
}

.trialInfo span {
    display: block;
    color: #4C6085;
    font-size: 14px;
    line-height: 14px;
    text-align: left;
}

.trialInfo span time {
    color: #4C6085;
}

.planInfo {
    display: flex;
    flex-direction: column;
}

.planInfo span {
    color: #3574F3;
    font-size: 16px;
    line-height: 16px;
    font-weight: bold;
    line-height: 30px;
    text-transform: capitalize;
}
