.comment-item {
    padding: 8px;
    margin-top: 8px;
    border-radius: 0px;
    background-color: #fff;
    border-bottom: 1px solid #dde4ed;
    position: relative;
    cursor: pointer;
    text-align: left;
}

.comment-item div {
    display: flex;
}

.comment-item-name {
    font-weight: bold;
    margin-right: 4px;
}

.comment-item img {
    height: 20px;
    border-radius: 10px;
    margin-right: 4px;
}

.comment-item-img-loading {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 15px;
    margin-right: 4px;
    background-color: #ddd;
}

.comment-item-createdAt time {
    font-size: 12px;
    opacity: 0.5;
}