.bugdetail-description textarea {
    width: 100%;
    min-height: 160px;
}

.bugdetail-description {
    cursor: pointer;
}

.bugdetail-bug-meta-card-data-oneliner {
    max-height: 24px;
    overflow: scroll;
}